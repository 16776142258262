import { useEffect, useState } from "react";
import { Navbar, Container, Nav, Row, Col, Image, Button } from 'react-bootstrap'
import '../ComponentsCss/Navigation.css'
import { useNavigate } from 'react-router-dom'
import { LinkContainer } from 'react-router-bootstrap'
import logo from '../Images/logowebsite.png'
export default function Navigation() {
    
    const navigater = useNavigate()
    const Admintoken = localStorage.getItem("adminToken")

    return (
        <div>
            <Container className="topHeader d-none d-md-block" fluid></Container>
            <Navbar collapseOnSelect expand="lg" variant="light" fixed="top" bg="white">
                <Container>
                    <Image src={logo} className="logimg" />
                    <Navbar.Brand href="#"><h4 style={{ fontWeight: 'bold', }}>HOTEL ROYAL PALACE
                        <br /><p className="brandHeading">Feels Like A Royal</p></h4>
                    </Navbar.Brand>
                    <Navbar.Toggle aria-controls="navbarScroll" />
                    <Navbar.Collapse id="navbarScroll " className="justify-content-end">
                        <Nav>
                            <LinkContainer to="/">
                                <Nav.Link className="nav-link headerLink">HOME</Nav.Link>
                            </LinkContainer>
                            <LinkContainer to="/room">
                                <Nav.Link href="#action1" className="nav-link headerLink">ROOMS</Nav.Link>
                            </LinkContainer>
                            <LinkContainer to="/event">
                                <Nav.Link href="#action1" className="nav-link headerLink">EVENT</Nav.Link>
                            </LinkContainer>
                            {/* <Nav.Link href="#action2" className="nav-link headerLink">ABOUT</Nav.Link>
                            <Nav.Link href="#action2" className="nav-link headerLink">CONTACT</Nav.Link> */}
                            <LinkContainer to="/carrier ">
                                <Nav.Link className="nav-link headerLink">CAREER</Nav.Link>
                            </LinkContainer>
                            {
                                Admintoken ?
                                    <>
                                        <LinkContainer to="/adminDashboard/addRoom">
                                            <Nav.Link className="nav-link headerLink">ADMIN DASHBOARD</Nav.Link>
                                        </LinkContainer>

                                        <Nav.Link className='headerLink' onClick={() => {
                                            localStorage.removeItem("adminToken")
                                            navigater("/admin")
                                        }} >SIGN OUT</Nav.Link>
                                    </>
                                    :
                                    <>
                                        <LinkContainer to="/admin">
                                            <Nav.Link className="nav-link headerLink">ADMIN LOGIN</Nav.Link>
                                        </LinkContainer>
                                    </>
                            }
                        </Nav>
                    </Navbar.Collapse>
                </Container>
            </Navbar>
        </div>
    )
}