import { useQuery } from '@apollo/client';
import React from 'react'
import { Table, Spinner, Container } from "react-bootstrap"
import { QUERY_CARRIER } from '../GraphQl/Query';
import Moment from 'react-moment'

export default function ViewCarrier() {

  let count = 1;
  const { data, loading } = useQuery(QUERY_CARRIER)
  console.log("data", data)
  return (
    <>
      <h5 style={{ marginTop: 10, fontFamily: "Dm sans", fontWeight: "bold" }}>All Carrier</h5>
      {
        loading ?
          <Spinner animation="border" style={{ marginTop: 100 }} />
          :
          <>

            <Table bordered style={{ fontFamily: "Dm sans", fontSize: 12 }}>
              <thead>
                <tr>
                  <th>SR No.</th>
                  <th>First Name</th>
                  <th>Last Name</th>
                  <th>Contact</th>
                  <th>Email</th>
                  <th>Experience</th>
                  <th>Address</th>
                  <th>Date/Time</th>
                  <th>Resume</th>
                </tr>
              </thead>
              <tbody>
                {
                  data && data.getAllCarrier.slice().reverse().map(carrier => {
                    return (
                      <tr>
                        <td>{count++}</td>
                        <td>{carrier.fName}</td>
                        <td>{carrier.lName}</td>
                        <td>{carrier.contact}</td>
                        <td>{carrier.email}</td>
                        <td>{carrier.experience}</td>
                        <td>{carrier.fullAddress}</td>
                        <td><Moment format="DD/MM/YYYY, hh:mm A">{carrier.createDateTime}</Moment></td>
                        <td><a href={`https://byaahlagan-profile-image.s3.us-east-2.amazonaws.com/${carrier.resumeUpload}`} download style={{ textDecoration: "none" }}>Download</a></td>
                      </tr>
                    )
                  })
                }
              </tbody>
            </Table>

            {
              data && data.getAllCarrier.length === 0 ?
                <Container>
                  <h1 style={{ fontFamily: 'Dm Sans', fontWeight: 'bold', textAlign: 'center', color: '#c0392b' }}>No Data Found</h1>
                </Container> : ''
            }
          </>
      }
    </>
  )
}
