import { useQuery } from '@apollo/client'
import React, { useState } from 'react'
import { Button, Table, Modal, Row, Col, Spinner, Container } from "react-bootstrap"
import { QUERY_BOOKING } from '../GraphQl/Query'
import { FaEye, FaTrashAlt } from "react-icons/fa";
import Moment from 'react-moment'

export default function AllBookingRoom() {

    let count = 1;
    const { data, loading } = useQuery(QUERY_BOOKING)

    console.log(data)

    const [show, setShow] = useState(false);
    const [detail, setDetail] = useState()

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    console.log(detail)

    function handleClick(book) {
        setDetail(book)
        handleShow()

    }

    console.log("data", data)

    return (
        <>
            {
                loading ?
                    <Spinner animation="border" style={{ marginTop: 100 }} />
                    :
                    <>
                        <Table striped bordered hover style={{ fontSize: 12, fontFamily: "Dm sans" }}>
                            <thead>
                                <tr>
                                    <th>SR No.</th>
                                    <th>First Name</th>
                                    <th>Last Name</th>
                                    <th>Contact</th>
                                    <th>Email</th>
                                    <th>Room Name</th>
                                    <th>Room Type</th>
                                    <th>Room No</th>
                                    <th>Room Price</th>
                                    <th>Date/Time</th>
                                    <th>Detail</th>
                                    {/* <th>Delete</th> */}
                                </tr>
                            </thead>
                            <tbody>
                                {
                                    data && data.getBooking.slice().reverse().map(book => {

                                        return (

                                            <tr>
                                                <td>{count++}</td>
                                                <td>{book.userFname}</td>
                                                <td>{book.userFname}</td>
                                                <td>{book.userContact}</td>
                                                <td>{book.userEmail}</td>
                                                <td>{book.roomName}</td>
                                                <td>{book.roomType}</td>
                                                <td>{book.roomNo}</td>
                                                <td>{book.roomPrice}</td>
                                                <td><Moment format='DD/MM/YYYY, hh:mm a'>{book.createdDateTime}</Moment></td>
                                                <td><Button onClick={() => handleClick(book)}><FaEye /></Button></td>
                                                {/* <td>  <Button variant="danger"><FaTrashAlt/></Button></td> */}
                                            </tr>

                                        )

                                    })



                                }


                            </tbody>
                        </Table>

                        {
                            data && data.getBooking.length === 0 ?
                                <Container>
                                    <h1 style={{ fontFamily: 'Dm Sans', fontWeight: 'bold', textAlign: 'center', color: '#c0392b' }}>No Data Found</h1>
                                </Container> : ''
                        }
                    </>


            }





            <Modal show={show} onHide={handleClose} size="lg">
                <Row>
                    <Col md={6}>
                        <h6 style={{ textAlign: "center", marginTop: 20, fontSize: 20, fontFamily: "Dm sans", fontWeight: "bold" }}>Room Information</h6>
                        <br></br>
                        <p style={{ marginLeft: 10, fontSize: 14, fontFamily: "Dm sans", color: "#000" }}>Room Name : {detail && detail.roomName} </p>
                        <p style={{ marginLeft: 10, fontSize: 14, fontFamily: "Dm sans", color: "#000" }}>Room Type : {detail && detail.roomType} </p>
                        <p style={{ marginLeft: 10, fontSize: 14, fontFamily: "Dm sans", color: "#000" }}>Room No : {detail && detail.roomNo} </p>
                        <p style={{ marginLeft: 10, fontSize: 14, fontFamily: "Dm sans", color: "#000" }}>Room Price : {detail && detail.roomPrice}</p>
                    </Col>
                    <Col md={6}>
                        <h6 style={{ textAlign: "center", marginTop: 20, fontSize: 20, fontFamily: "Dm sans", fontWeight: "bold" }}>User Information</h6>
                        <br></br>
                        <p style={{ marginLeft: 10, fontSize: 14, fontFamily: "Dm sans", color: "#000" }}> Name : {detail && detail.userFname} {detail && detail.userLname}</p>
                        <p style={{ marginLeft: 10, fontSize: 14, fontFamily: "Dm sans", color: "#000" }}> Contact : {detail && detail.userContact} </p>
                        <p style={{ marginLeft: 10, fontSize: 14, fontFamily: "Dm sans", color: "#000" }}>Email : {detail && detail.userEmail}</p>
                        <p style={{ marginLeft: 10, fontSize: 14, fontFamily: "Dm sans", color: "#000" }}>City : {detail && detail.userCity}</p>
                        <p style={{ marginLeft: 10, fontSize: 14, fontFamily: "Dm sans", color: "#000" }}>State : {detail && detail.userState}</p>
                        <p style={{ marginLeft: 10, fontSize: 14, fontFamily: "Dm sans", color: "#000" }}>Address : {detail && detail.userAddress}</p>
                    </Col>
                </Row>
            </Modal>
        </>
    )
}
