/* eslint-disable jsx-a11y/iframe-has-title */

import { useMutation } from '@apollo/client'
import React from 'react'
import { useState } from 'react'
import { Container, Row, Col, Image, Button, Card, Form, FormControl } from 'react-bootstrap'
import { MUTATION_CONTACT } from '../GraphQl/Mutation'
import swal from 'sweetalert';
export default function Contactus() {

   const[fullName,setFullName] = useState("")
   const [email,setEmail] =useState("")
   const [contact,setContact] =useState("")
   const [message,setMessage] =useState("")

     const[createContact] = useMutation(MUTATION_CONTACT)
    function handleClick(){
        createContact({
        variables:{
            "contactInput": {
                "fullName": `${fullName}`,
                "email": `${email}`,
                "contact": `${contact}`,
                "message": `${message}`,
               
              }

        }


        })
        swal({ title: 'Thank for Contact Us', icon: 'success' });

        setFullName("")
        setContact("")
        setEmail("")
        setMessage("")


       
    }

    return (
        <Container >
            <Row>
                <Col md={12} style={{ textAlign: 'center', marginTop: 50 }}>
                    <h2 style={{ color: 'black', fontWeight: 'bold', fontFamily: 'Kaushan Script' }} >
                        CONTACT US
                    </h2>
                    <h2 className='mx-auto d-block' style={{ backgroundColor: '#ffb606 ', height: 2, width: '10%', marginTop: 20 }}> </h2>
                    <Container>
                        <p style={{ marginTop: 20, color: ' #666666', fontWeight: 400, fontFamily: 'Poppins' }}>
                            Enter your details and we will connect you with the right person.
                        </p>
                    </Container>
                </Col>
            </Row>

            <Col md={12} style={{

                marginTop: 25
            }}>
                <Row style={{
                    backgroundColor: 'rgba(20, 23, 28, 0.1)',
                    height: 2,
                    marginBottom: 30
                }}>

                </Row>

            </Col>

            <Row>
                <Col md={6}>
                    <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3650.457482092578!2d78.74751631434943!3d23.802325892709085!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3978d41027d89721%3A0x856cd4e55932cd16!2sHotel%20Royal%20Palace!5e0!3m2!1sen!2sin!4v1660729372940!5m2!1sen!2sin" loading="lazy" referrerpolicy="no-referrer-when-downgrade" style={{ width: '100%', padding: 20, height: 450 }}></iframe>
                </Col>
                <Col md={6} style={{ marginTop: 35 }}>
                    <Form>
                        <Row>
                            <Col md={12}>
                                <Form.Control type="text" placeholder="Enter Your Name" style={{ fontFamily: 'Poppins' }} onChange={(e)=>setFullName(e.target.value)}  value={fullName}/>
                            </Col>
                        </Row>
                        <Row style={{ marginTop: 25 }}>
                            <Col md={12}>
                                <Form.Control type="email" placeholder="Enter Your Email* " style={{ fontFamily: 'Poppins' }} onChange={(e)=>setEmail(e.target.value)}  value={email}/>
                            </Col>
                        </Row>
                        <Row style={{ marginTop: 25 }}>
                            <Col md={12}>
                                <Form.Control type="number" placeholder="Enter Your Phone Number* " style={{ fontFamily: 'Poppins' }} onChange={(e)=>setContact(e.target.value)} value={contact} />
                            </Col>
                        </Row>
                        <Row style={{ marginTop: 25 }}>
                            <Col md={12}>
                                <Form.Control as="textarea" placeholder="Enter Your Message* " rows={7} style={{ fontFamily: 'Poppins' }} onChange={(e)=>setMessage(e.target.value)} value={message}/>
                            </Col>
                        </Row>
                        <Button style={{ width: '40%', height: 50, color: 'black', fontWeight: 'bold', marginTop: 25, marginBottom: 10, fontFamily: 'Poppins' }} className="mx-auto d-block" variant="warning"  onClick={()=>handleClick()}> Send </Button>{' '}
                    </Form>
                </Col>
            </Row>
        </Container>
    )
}