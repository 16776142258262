import React from 'react'
import { Col, Container, Row, Image } from 'react-bootstrap'
import '../ComponentsCss/AboutUs.css'
import ab1 from '../Images/DSCN5575.JPG'
import ab2 from '../Images/DSCN5570.JPG'
export default function AboutUs() {
    return (
        <Container style={{ marginTop: 50 }}>
            <Row>
                <Col md={6}>
                    <h1 className='Abouth1'>About Us </h1>
                    <p className='paragraph' style={{ fontSize: 15 }}>
                        Welcome to  <b> HOTEL ROYAL PALACE </b>
                        We welcome you with genuine hospitality and a professional service attitude. We strive for 100% guest satisfaction in every sphere of your stay with us.
                        Besides providing our world-class facilities, we offer clean, comfortable, and spacious accommodation, ensuring a memorable stay and value for money. Stay or dine in at Hotel Royal Palace and experience the royal hospitality.
                        We are ideally located in the city and in close proximity to major landmarks of Sagar city. Choose your way to visit us.
                    </p>
                </Col>
                <Col md={6}>
                    <Row style={{ marginTop: 55 }}>
                        <Col md={6}>
                            <Image src={ab1}
                                className='img-fluid' style={{ height: 250, width: '100%', padding: 10, borderRadius: 20 }}
                            />
                        </Col>
                        <Col md={6}>
                            <Image src={ab2}
                                className='img-fluid' style={{ height: 250, width: '100%', padding: 10, borderRadius: 20 }} />
                        </Col>
                    </Row>
                </Col>
            </Row>
        </Container>
    )
}
