import { useQuery } from '@apollo/client'
import React, { useState } from 'react'
import { Container, Row, Col, Image, Button, Card, Carousel, Modal, Spinner } from 'react-bootstrap'
import { useNavigate } from 'react-router-dom'
import '../ComponentsCss/RoomDetails.css'
import { QUERY_ROOM } from '../GraphQl/Query'
import Navigation from './Navigation'

export default function Room() {

    const { data, loading } = useQuery(QUERY_ROOM)

    const [roomData, setRoomData] = useState()

    const navigate = useNavigate()

    const [show, setShow] = useState(false);

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    function handleClick(data) {
        setRoomData(data)
        handleShow()
    }

    function handleBooking(id) {
        navigate("/Booking/" + id)

    }


    return (
        <>
            <Navigation />
            <Container style={{ marginTop: 150 }} >
                {
                    loading ?
                        <Spinner animation="border" role="status" className="mx-auto d-block" /> :
                        <Row>
                            {
                                data && data.getAllRoom.slice().reverse().map(room => {
                                    return (
                                        <Col md={4}>
                                            <Row>
                                                <Card style={{ border: "2px solid #feca57", width: "90%", height: '100%', marginTop: 20 }} className="mx-auto d-block">
                                                    <img
                                                        className="d-block w-100 img-fluid"
                                                        src={`https://hotelroyalpalace.s3.amazonaws.com/${room.roomImg[0]}`}
                                                        alt="First slide"
                                                        style={{ width: '100%', height: '90%' }}
                                                    />
                                                    <h5 style={{ marginTop: 10, fontFamily: "Dm sans", fontWeight: "bold" }}>{room.roomType}</h5>
                                                    <h6 style={{ marginTop: 10, fontFamily: "Dm sans" }}>{room.roomDiscription}</h6>
                                                    {
                                                        room.availability === 'available' ?
                                                            <p style={{ color: "#2ecc71", padding: 10, fontFamily: 'DM sans', fontSize: '15px', fontWeight: 'bold' }}>Available</p> :
                                                            <p style={{ color: "#e74c3c", padding: 10, fontFamily: 'DM sans', fontSize: '15px', fontWeight: 'bold' }}>Not Available</p>
                                                    }
                                                    <Button className="mx-auto d-block" style={{ width: 200, marginBottom: 10, marginLeft: 10, background: "#feca57", color: "#000", border: "2px solid #feca57", fontFamily: "Dm sans", fontWeight: "800" }} onClick={() => handleClick(room)}>Check More Detail</Button>
                                                </Card>
                                            </Row>
                                        </Col>
                                    )
                                })
                            }
                        </Row>
                }


            </Container>

            <Modal show={show} onHide={handleClose} size="lg">
                <Container>
                    <Row>
                        <Col md={6}>
                            <Carousel>
                                {
                                    roomData && roomData.roomImg.map(img => {

                                        return (
                                            <Carousel.Item>
                                                <img
                                                    className="d-block w-100 img-fluid"
                                                    src={`https://hotelroyalpalace.s3.amazonaws.com/${img}`}
                                                    alt="First slide"
                                                    style={{ margin: 10 }}
                                                />
                                                <Carousel.Caption>

                                                </Carousel.Caption>
                                            </Carousel.Item>
                                        )
                                    })
                                }
                            </Carousel>
                        </Col>
                        <Col md={6}>
                            <h5 style={{ textAlign: "center", marginTop: 10 }}>{roomData && roomData.roomName}</h5>
                            <h6 style={{ textAlign: "center", marginTop: 10 }}>{roomData && roomData.roomDiscription}</h6>
                            <p style={{ fontFamily: "Dm sans" }}>  Room Type : {roomData && roomData.roomType}</p>
                            <p style={{ fontFamily: "Dm sans" }}>  Room Number : {roomData && roomData.roomNo}</p>
                            <Row>
                                <Col>
                                    <p style={{ fontFamily: "Dm sans", color: "#000", fontWeight: "600" }}>Facility</p>
                                    <ul>
                                        {roomData && roomData.facility}
                                    </ul>
                                </Col>
                                <Col>
                                    <p style={{ fontFamily: "Dm sans", color: "#000", fontWeight: "600" }}>Service</p>
                                    <ul>
                                        {roomData && roomData.service}
                                    </ul>
                                </Col>
                            </Row>
                            <Row>
                                <Col>
                                    <p style={{ fontFamily: "Dm sans", color: "#000", fontWeight: "600" }}>Feature</p>
                                    <ul>
                                        {roomData && roomData.feature}
                                    </ul>
                                </Col>
                            </Row>
                            <Row>
                                <Col>
                                    <h5 style={{}}>Price : {roomData && roomData.price}/-</h5>
                                </Col>
                                <Col>
                                    <Button style={{ border: "none", background: "#feca57", color: "#000", fontWeight: "bold", fontFamily: "Dm sans" }} onClick={() => handleBooking(roomData && roomData.id)}>Book Now</Button>
                                </Col>
                            </Row>

                        </Col>
                    </Row>

                </Container>
            </Modal>
        </>
    )
}
