/* eslint-disable react/jsx-no-target-blank */
import React from 'react'
import '../ComponentsCss/Footer.css'
import { Col, Container, Row } from 'react-bootstrap'
import { ImLocation2, ImMail } from 'react-icons/im'
import { BsFillTelephoneFill, BsFacebook, BsTwitter, BsLinkedin, BsInstagram, BsPinterest } from 'react-icons/bs'

const Footer = () => {
    return (
        <>
            <div id='Footer-div'>

                <Container>
                    <Row>
                        <Col md={4}>
                            <center>
                                <p className='heading'>
                                    Contact Us
                                </p>
                                <p style={{ fontFamily: 'Poppins' }}>
                                    <ImLocation2 />&nbsp; Kila Kothi, Rajghat Road, Tilli, Sagar (M.P) 470002
                                </p>
                                <p style={{ fontFamily: 'Poppins' }}>
                                    <BsFillTelephoneFill />&nbsp; Call +91-7440442111, +91-7440442102
                                </p>
                                <p style={{ fontFamily: 'Poppins' }}>
                                    <ImMail />&nbsp; royalpalacesagar@gmail.com
                                </p>
                            </center>
                        </Col>
                        <Col md={4}>
                            <center>
                                <p className='heading'>
                                    Hotel Royal Palace
                                </p>
                                <p style={{ fontFamily: 'Poppins' }}>
                                    A Real Destination To Enjoy The Taste Of Royal Life
                                </p>
                                <h4>
                                    <a href="https://www.facebook.com/HOTELROYALPalaceSagar" target="_blank" style={{ color: '#fff', marginLeft: 10 }}><BsFacebook /></a>
                                    <a href="https://twitter.com/royalpalacesag" target="_blank" style={{ color: '#fff', marginLeft: 10 }}><BsTwitter /></a>
                                    <a href="https://www.instagram.com/hotelroyalpalace54/" target="_blank" style={{ color: '#fff', marginLeft: 10 }}><BsInstagram /></a>
                                </h4>
                            </center>
                        </Col>
                        <Col md={4}>
                            <center>
                                <p className='heading'>
                                    Opening Hours
                                </p>
                                <p style={{ fontFamily: 'Poppins' }}>Everyday</p>
                                <p style={{ fontFamily: 'Poppins' }}>24 Hours</p>
                            </center>
                        </Col>
                        <Col md={12}>
                            <br /><br /><br />
                            <center>
                                <p style={{ fontFamily: 'Poppins' }}>© 2022 All Rights Reserved By Hotel Royal Palace Sagar</p>
                                <p style={{ fontFamily: 'Poppins', fontSize: '12px', marginTop: '-15px' }}>Design and maintain by <a href="https://softseekersinfotech.com/" target="_blank" style={{ color: '#fff', textDecoration: 'none' }}>Softseekers Infotech Pvt. Ltd.</a></p>
                            </center>
                        </Col>
                        <Col md={12}>
                            <br />

                        </Col>
                    </Row>
                </Container>

            </div>
        </>
    )
}

export default Footer