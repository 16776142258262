
import React from 'react'
import { Container, Row, Col, Image, Button, Card } from 'react-bootstrap'
import '../ComponentsCss/RoomDetails.css'
import bed from '../Images/bed.jpg'
import { Link } from 'react-router-dom'
export default function RoomDetails() {
    return (
        <Container fluid style={{ marginTop: 50 }} >
            <Row>
                <Col md={5}>
                    <Image src={bed} className='img-fluid' style={{ height: 430, width: '100%' }} />
                </Col>
                <Col md={7}>
                    <h2 style={{ color: 'black', fontWeight: 'bold', fontFamily: 'Kaushan Script' }}>
                        Delux Room (AC)
                    </h2>
                    <p style={{ marginTop: 10, color: ' #666666', fontWeight: 400, fontFamily: 'Poppins' }}>
                        Hotel Royal Place has super specialty rooms, an attached bathroom with elegant furniture, and beautiful interiors. The rooms are equipped with all the modern amenities, enabling the occupant to have a memorable stay.
                    </p>
                    <p style={{ marginTop: 20, fontWeight: 'bold', fontSize: 20, color: ' #002147', fontFamily: 'Poppins' }}>
                        Facilities in Room
                    </p>
                    <p style={{ marginTop: 10, fontWeight: 900, color: ' #666666', fontFamily: 'Poppins' }}>
                        Delux AC Room
                    </p>
                    <p style={{ marginTop: 10, fontWeight: 400, color: ' #666666', fontFamily: 'Poppins' }}>
                        <span style={{ marginTop: 10, fontWeight: 600, color: ' black' }}>Price:</span> Rs. 1800/-
                    </p>
                    <p style={{ marginTop: 10, fontWeight: 400, color: ' #666666', fontFamily: 'Poppins' }}>
                        <span style={{ marginTop: 10, fontWeight: 600, color: ' black' }}>Features: </span>AC, Free Wi-Fi, Bathroom, Heater, In-room Dining and many more
                    </p>
                    <p style={{ marginTop: 10, fontWeight: 400, color: ' #666666', fontFamily: 'Poppins' }}>
                        <span style={{ marginTop: 10, fontWeight: 600, color: ' black' }}>Best Service:</span> Telephone, Hangers, Charging Points, Mirror, Sofa etc.
                    </p>
                    <Link to='/room'><Button style={{ height: 50, color: 'black', fontWeight: 'bold', marginTop: 10, fontFamily: 'Poppins' }} variant="warning">Check Availability </Button></Link>
                </Col>


            </Row>
        </Container>

    )
}
