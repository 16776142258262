import React, { useState } from 'react'
import { Container, Row, Col, Image, Form, Button, InputGroup, Spinner } from 'react-bootstrap'
import Navigation from './Navigation'
import Img from "../Images/Booking.png"
import swal from "sweetalert";
import { useMutation, useQuery } from '@apollo/client';
import { MUTATION_BOOOKING } from '../GraphQl/Mutation';
import { QUERY_ROOM_BY_ID } from '../GraphQl/Query';
import { LoneSchemaDefinitionRule } from 'graphql';
import { useParams, Link, useNavigate } from 'react-router-dom'
export default function Booking() {

    const [validated, setValidated] = useState(false);
    const { id } = useParams();

    const { data: dataRoom, loading: dataRoomLoading } = useQuery(QUERY_ROOM_BY_ID, {
        variables: {
            "roomId": `${id}`
        }
    })

    console.log(dataRoom)

    const [fName, setFName] = useState("")
    const [lName, setLName] = useState("")
    const [email, setEmail] = useState("")
    const [contact, setContact] = useState("")
    const [city, setCity] = useState("")
    const [state, setState] = useState("")
    const [address, setAddress] = useState("")


    const [createBooking, { loading, data }] = useMutation(MUTATION_BOOOKING)

    const handleSubmit = (event) => {
        const form = event.currentTarget;
        if (form.checkValidity() === false) {
            event.preventDefault();
            event.stopPropagation();
        } else {
            event.preventDefault();
            createBooking({
                variables: {
                    "bookingInput": {
                        "roomName": `${dataRoom && dataRoom.getRoomById.roomName}`,
                        "roomNo": `${dataRoom && dataRoom.getRoomById.roomNo}`,
                        "roomType": `${dataRoom && dataRoom.getRoomById.roomType}`,
                        "roomId": `${dataRoom && dataRoom.getRoomById.id}`,
                        "roomPrice": `${dataRoom && dataRoom.getRoomById.price}`,
                        "userFname": `${fName}`,
                        "userLname": `${lName}`,
                        "userContact": `${contact}`,
                        "userEmail": `${email}`,
                        "userAddress": `${address}`,
                        "userCity": `${city}`,
                        "userState": `${state}`,
                    }
                }
            })
            swal({
                title: 'Successfull!!!',
                text: ' Uploaded Successfully',
                icon: 'success',
            });
            setFName("")
            setLName("")
            setEmail("")
            setContact("")
            setCity("")
            setState("")
            setAddress("")


        }

        setValidated(true);
    };

    return (
        <>
            <Navigation />
            <br></br><br></br><br></br><br></br><br></br>
            <Container style={{ backgroundColor: "#feca57" }}>
                {
                    dataRoomLoading ?
                        <Spinner animation="border" role="status" className="mx-auto d-block" /> :

                        <Row>
                            <Col md={6}>
                                <Image src={Img} className="img-fluid" alt="Responsive image" style={{ height: 450 }} />
                            </Col>
                            <Col md={6}>
                                <h3 style={{ marginTop: 30, fontFamily: "Dm sans", fontWeight: "800" }}>Room Booking</h3>
                                <Row>
                                    <Col>
                                        <h6 style={{ justifyContent: "flex-start", textAlign: "start" }}>Room Name : {dataRoom && dataRoom.getRoomById.roomName}</h6>
                                    </Col>
                                    <Col>
                                        <h6 style={{ justifyContent: "flex-start", textAlign: "start" }}>Room No : {dataRoom && dataRoom.getRoomById.roomNo}</h6>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col>
                                        <h6 style={{ justifyContent: "flex-start", textAlign: "start" }}>Room Type : {dataRoom && dataRoom.getRoomById.roomType}</h6>
                                    </Col>
                                    <Col>
                                        <h6 style={{ justifyContent: "flex-start", textAlign: "start" }}>Room Price : {dataRoom && dataRoom.getRoomById.price} /-</h6>
                                    </Col>
                                </Row>
                                <br></br>
                                <h6 style={{ fontFamily: "Dm sans", color: "#000", fontWeight: "bold" }}>Fill Up Your Information</h6>
                                <hr></hr>

                                <Form noValidate validated={validated} onSubmit={handleSubmit}>
                                    <Row className="mb-3">
                                        <Form.Group as={Col} md="6" controlId="validationCustom01">
                                            <Form.Control required type="text" value={fName} onChange={(e) => setFName(e.target.value)} style={{ fontFamily: "Dm sans", background: "transparent", border: "none", borderBottom: "2px solid #000" }} placeholder="Enter First name" />
                                        </Form.Group>
                                        <Form.Group as={Col} md="6" controlId="validationCustom02">
                                            <Form.Control required type="text" value={lName} onChange={(e) => setLName(e.target.value)} style={{ fontFamily: "Dm sans", background: "transparent", border: "none", borderBottom: "2px solid #000" }} placeholder="Enter Last name" />
                                        </Form.Group>
                                    </Row>
                                    <Row className="mb-3">
                                        <Form.Group as={Col} md="6" controlId="validationCustom01">
                                            <Form.Control required type="text" value={email} onChange={(e) => setEmail(e.target.value)} style={{ fontFamily: "Dm sans", background: "transparent", border: "none", borderBottom: "2px solid #000" }} placeholder=" Enter Email Id" />
                                        </Form.Group>
                                        <Form.Group as={Col} md="6" controlId="validationCustom02">
                                            <Form.Control required type="text" value={contact} onChange={(e) => setContact(e.target.value)} style={{ fontFamily: "Dm sans", background: "transparent", border: "none", borderBottom: "2px solid #000" }} placeholder="Enter Contact" />
                                        </Form.Group>
                                    </Row>
                                    <Row className="mb-3">
                                        <Form.Group as={Col} md="6" controlId="validationCustom01">
                                            <Form.Control required type="text" value={city} onChange={(e) => setCity(e.target.value)} style={{ fontFamily: "Dm sans", background: "transparent", border: "none", borderBottom: "2px solid #000" }} placeholder="Enter City" />
                                        </Form.Group>
                                        <Form.Group as={Col} md="6" controlId="validationCustom02">
                                            <Form.Select size="sm" style={{ fontFamily: "Dm sans", background: "transparent", border: "none", borderBottom: "2px solid #000", marginTop: 10 }} onChange={(e) => setState(e.target.value)} value={state}>
                                                <option>Select State</option>
                                                <option value="Andhra Pradesh">Andhra Pradesh</option>
                                                <option value="Andaman and Nicobar Islands">Andaman and Nicobar Islands</option>
                                                <option value="Arunachal Pradesh">Arunachal Pradesh</option>
                                                <option value="Assam">Assam</option>
                                                <option value="Bihar">Bihar</option>
                                                <option value="Chandigarh">Chandigarh</option>
                                                <option value="Chhattisgarh">Chhattisgarh</option>
                                                <option value="Dadar and Nagar Haveli">Dadar and Nagar Haveli</option>
                                                <option value="Daman and Diu">Daman and Diu</option>
                                                <option value="Delhi">Delhi</option>
                                                <option value="Lakshadweep">Lakshadweep</option>
                                                <option value="Puducherry">Puducherry</option>
                                                <option value="Goa">Goa</option>
                                                <option value="Gujarat">Gujarat</option>
                                                <option value="Haryana">Haryana</option>
                                                <option value="Himachal Pradesh">Himachal Pradesh</option>
                                                <option value="Jammu and Kashmir">Jammu and Kashmir</option>
                                                <option value="Jharkhand">Jharkhand</option>
                                                <option value="Karnataka">Karnataka</option>
                                                <option value="Kerala">Kerala</option>
                                                <option value="Madhya Pradesh">Madhya Pradesh</option>
                                                <option value="Maharashtra">Maharashtra</option>
                                                <option value="Manipur">Manipur</option>
                                                <option value="Meghalaya">Meghalaya</option>
                                                <option value="Mizoram">Mizoram</option>
                                                <option value="Nagaland">Nagaland</option>
                                                <option value="Odisha">Odisha</option>
                                                <option value="Punjab">Punjab</option>
                                                <option value="Rajasthan">Rajasthan</option>
                                                <option value="Sikkim">Sikkim</option>
                                                <option value="Tamil Nadu">Tamil Nadu</option>
                                                <option value="Telangana">Telangana</option>
                                                <option value="Tripura">Tripura</option>
                                                <option value="Uttar Pradesh">Uttar Pradesh</option>
                                                <option value="Uttarakhand">Uttarakhand</option>
                                                <option value="West Bengal">West Bengal</option>
                                            </Form.Select>
                                        </Form.Group>
                                    </Row>
                                    <Row>
                                        <Form.Group as={Col} md="12" controlId="validationCustom02">
                                            <Form.Control required type="text" value={address} onChange={(e) => setAddress(e.target.value)} style={{ fontFamily: "Dm sans", background: "transparent", border: "none", outline: "none", borderBottom: "2px solid #000" }} placeholder="Address" />
                                        </Form.Group>
                                    </Row>
                                    <Form.Group className="mb-3">
                                    </Form.Group>
                                    {
                                        loading ?
                                            <Spinner animation="border" />
                                            :
                                            <Button type="submit" style={{ border: "none", background: "#000", width: 200, marginBottom: 10 }}>Book Now</Button>
                                    }
                                </Form>
                            </Col>
                        </Row>
                }



            </Container>

        </>
    )
}
