import React, { useState } from 'react'
import { Container, Col, Row, Button, Form, InputGroup, Modal, Spinner, Image } from 'react-bootstrap'
import AWS from "aws-sdk";
import Resizer from "react-image-file-resizer";
import uniqid from "uniqid";
import Dropzone from "react-dropzone-uploader";
import Img from "../Images/e2.jpg"
import 'react-dropzone-uploader/dist/styles.css'
import swal from "sweetalert";
import { useMutation } from '@apollo/client';
import { MUTATION_EVENT } from '../GraphQl/Mutation';
import { QUERY_EVENT } from '../GraphQl/Query';

const ID = "AKIA6GB4RFKTM63VVHEK";
const SECRET = "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";
const BUCKET_NAME = "hotelroyalpalace";


const s3 = new AWS.S3({
  accessKeyId: ID,
  secretAccessKey: SECRET,
});

const imageKey = []
export default function AddEvent() {

  const [validated, setValidated] = useState(false);

  const [eventtitle, setEventTilte] = useState('');
  const [eventDes, setEventDes] = useState('')
  const [eventDate, setEventDate] = useState('')
  const [eventstartT, setEventStartT] = useState('')
  const [eventEndT, setEventEndT] = useState('')
  const [eventVenue, setEventVenue] = useState('')

  const [imageBox, setImageBox] = useState()
  const [uploadDone, setUploadDone] = useState(false)
  const [uploadLoading, setUploadLoading] = useState(false)
  const [selectImage, setSelectImage] = useState()
  const [multipleImgKey, setMultipleImgKey] = useState([])

  function multipleImg(e) {
    setSelectImage([...e.target.files])
  }

  const [createEvent] = useMutation(MUTATION_EVENT, {
    refetchQueries: [
      QUERY_EVENT
    ]
  })

  const handleSubmit = (event) => {
    const form = event.currentTarget;
    if (form.checkValidity() === false) {
      event.preventDefault();
      event.stopPropagation();
      setValidated(true);
    }
    else {
      setValidated(false);
      event.preventDefault();
      try {
        setUploadLoading(true)
        selectImage.map(async (fileData) => {
          const uniqueId = uniqid();
          var regex = new RegExp('[^.]+$');
          var extension = fileData.name.match(regex);
          var uniqueFileName = "HotelImg" + '-' + uniqueId + '-' + uniqueId + '.' + extension[0];
          imageKey.push(uniqueFileName)
          const params = {
            Bucket: BUCKET_NAME,
            Key: uniqueFileName,
            Body: fileData,
          };
          s3.upload(params, function (err, data) {
            if (err) {
              throw err;
            } else {
              setImageBox(uniqueFileName)
              swal({
                title: 'Successfull!!!',
                text: ' Uploaded Successfully',
                icon: 'success',
              });
              setMultipleImgKey([])
              setSelectImage('')
              setUploadLoading(false)
              setUploadDone(true)
              event.reset()
            }
          });
        })
      } catch (err) {
        swal({
          title: 'Error!!!',
          text: 'Error please try again',
          icon: 'error',
        });
        console.log(err);
        setUploadLoading(false)
      }
      createEvent({
        variables: {
          "eventInput": {
            "eventDate": `${eventDate}`,
            "eventDescription": `${eventDes}`,
            "eventEndingTime": `${eventEndT}`,
            "eventImage": imageKey,
            "eventTitle": `${eventtitle}`,
            "eventStartingTime": `${eventstartT}`,
            "eventVenue": `${eventVenue}`
          }
        }
      })
      setEventTilte('')
      setEventDes('')
      setEventDate('')
      setEventStartT('')
      setEventEndT('')
      setEventVenue('')
    }
  };

  return (
    <>
      <Container style={{ border: "2px solid #fbc531", marginTop: 10 }}>

        <Row>
          <Col md={12}>
            <Form noValidate validated={validated} onSubmit={handleSubmit} style={{ marginTop: 30 }}>
              <Row className="mb-3">
                <Form.Group as={Col} md="4" controlId="validationCustom01">
                  <Form.Label style={{ fontFamily: "Dm sans", fontSize: 13, fontWeight: 'bold' }}>Event Title</Form.Label>
                  <Form.Control
                    required
                    type="text"
                    placeholder=" Event Title"
                    style={{ fontFamily: "Dm sans", fontSize: 13 }}
                    onChange={(e) => setEventTilte(e.target.value)}
                    value={eventtitle}
                  />

                </Form.Group>
                <Form.Group as={Col} md="8" controlId="validationCustom02">
                  <Form.Label style={{ fontFamily: "Dm sans", fontSize: 13, fontWeight: 'bold' }}>Event Descrition</Form.Label>
                  <Form.Control
                    required
                    as="textarea"
                    rows={1}
                    placeholder="Event Description"
                    style={{ fontFamily: "Dm sans", fontSize: 13 }}
                    onChange={(e) => setEventDes(e.target.value)}
                    value={eventDes}
                  />

                </Form.Group>
              </Row>


              <Row className="mb-3">
                <Form.Group as={Col} md="4" controlId="validationCustom03">
                  <Form.Label style={{ fontFamily: "Dm sans", fontSize: 13, fontWeight: 'bold' }}>Event Date</Form.Label>
                  <Form.Control type="date" placeholder="date" onChange={(e) => setEventDate(e.target.value)} required style={{ fontFamily: "Dm sans", fontSize: 13 }} value={eventDate} />

                </Form.Group>
                <Form.Group as={Col} md="4" controlId="validationCustom04">
                  <Form.Label style={{ fontFamily: "Dm sans", fontSize: 13, fontWeight: 'bold' }}>Event Start Time</Form.Label>
                  <Form.Control type="time" placeholder="Event Start Time" onChange={(e) => setEventStartT(e.target.value)} required style={{ fontFamily: "Dm sans", fontSize: 13 }} value={eventstartT} />

                </Form.Group>
                <Form.Group as={Col} md="4" controlId="validationCustom05" >
                  <Form.Label style={{ fontFamily: "Dm sans", fontSize: 13, fontWeight: 'bold' }}>Event End Time</Form.Label >
                  <Form.Control type="time" placeholder="Event End Time" required onChange={(e) => setEventEndT(e.target.value)} style={{ fontFamily: "Dm sans", fontSize: 13 }} value={eventEndT} />

                </Form.Group>
              </Row>
              <Row className="mb-3">
                <Form.Group as={Col} md="4" controlId="validationCustom01">
                  <Form.Label style={{ fontFamily: "Dm sans", fontSize: 13, fontWeight: 'bold' }}>Event Venue</Form.Label>
                  <Form.Control
                    required
                    type="text"
                    placeholder=" Event Venue"
                    onChange={(e) => setEventVenue(e.target.value)}
                    style={{ fontFamily: "Dm sans", fontSize: 13 }}
                    value={eventVenue}
                  />

                </Form.Group>
                <Form.Group as={Col} md="8" controlId="validationCustom02">
                  <Form.Label style={{ fontFamily: "Dm sans", fontSize: 13, fontWeight: 'bold' }}>Upload Multiple</Form.Label>
                  <Form.Control style={{ fontSize: '12px' }} type="file" name="file" onChange={multipleImg} multiple accept="image/*" />

                </Form.Group>
              </Row>
              {
                uploadLoading ?
                  <Spinner animation="border" role="status"></Spinner> :
                  <Button type="submit" style={{ fontFamily: "Dm sans", background: "#feca57", border: "2px solid #feca57", color: "#000", fontWeight: "bold", marginBottom: '15px' }}>Add Event</Button>
              }
            </Form>
          </Col>
        </Row>
      </Container>


    </>
  )
}
