import { useMutation, useQuery } from '@apollo/client';
import React from 'react'
import { Button, Table, Spinner, Container } from "react-bootstrap"
import { MUTATION_DELETE_ROOM } from '../GraphQl/Mutation';
import { FiTrash2, FiEdit } from "react-icons/fi";
import { QUERY_ROOM } from '../GraphQl/Query';

export default function AllRoom() {


  let count = 1;

  const { data, loading } = useQuery(QUERY_ROOM)


  const [deleteRoom] = useMutation(MUTATION_DELETE_ROOM, {
    refetchQueries: [
      QUERY_ROOM,
      "getAllRoom"
    ]
  })

  function handleDelete(id) {
    deleteRoom({
      variables: {
        "roomId": `${id}`
      }
    })
  }

  console.log("data", data)

  return (
    <>
      <h5 style={{ marginTop: 10, fontFamily: "Dm sans", fontWeight: "bold" }}>All Room</h5>
      {
        loading ?
          <Spinner animation="border" style={{ marginTop: 100 }} />
          :
          <>
            <Table striped bordered hover style={{ fontFamily: "Dm sans", fontSize: 14 }}>
              <thead>
                <tr>
                  <th>SR No.</th>
                  <th>Room Name</th>
                  <th>Roomt No</th>
                  <th>Room Type</th>
                  <th>Room Description</th>
                  <th>Facility</th>
                  <th>Feature</th>
                  <th>Service</th>
                  <th>Price</th>
                  <th>Availability</th>
                  <th>Delete</th>
                  <th>Edit</th>
                </tr>
              </thead>
              <tbody>

                {
                  data && data.getAllRoom.slice().reverse().map(room => {
                    return (
                      <tr>
                        <td>{count++}</td>
                        <td>{room.roomName}</td>
                        <td>{room.roomNo}</td>
                        <td>{room.roomType}</td>
                        <td>{room.roomDiscription}</td>
                        <td>{room.facility}</td>
                        <td>{room.feature}</td>
                        <td>{room.service}</td>
                        <td>{room.price}</td>
                        <td>{room.availability}</td>
                        <td><Button style={{ background: "red" }} onClick={() => handleDelete(room.id)}><FiTrash2 /></Button></td>
                        <td><Button style={{ background: "green" }}><FiEdit /></Button></td>
                      </tr>
                    )
                  })
                }
              </tbody>
            </Table>
          </>
      }
      {
        data && data.getAllRoom.length === 0 ?
          <Container>
            <h1 style={{ fontFamily: 'Dm Sans', fontWeight: 'bold', textAlign: 'center', color: '#c0392b' }}>No Data Found</h1>
          </Container> : ''
      }
    </>
  )
}
