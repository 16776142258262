import { useMutation, useQuery } from '@apollo/client'
import React, { useState } from 'react'
import { Table, Button, Modal, Form, Row, Col, Spinner, Container } from "react-bootstrap"
import { QUERY_EVENT } from '../GraphQl/Query'
import { FiTrash2, FiEdit } from "react-icons/fi";
import { MUTATION_DELETE_EVENT, MUTATION_UPDATE_EVENT } from '../GraphQl/Mutation';
import Moment from 'react-moment'

export default function AllEvent() {

  let count = 1;



  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const [event, setEvent] = useState();
  const [stop, setStop] = useState(false);





  const [eventId, setEventId] = useState();
  const [eventTitle, setEventTitle] = useState();
  const [eventDes, setEventDes] = useState();
  const [evenDate, setEventDate] = useState();
  const [eventStartT, setEventStartT] = useState();
  const [eventEndT, setEventEndT] = useState();
  const [eventVenue, setEventVenue] = useState();


  if (event && stop === false) {
    setEventId(event && event.id)
    setEventTitle(event && event.eventTitle)
    setEventDes(event && event.eventDescription)
    setEventDate(event && event.eventDate)
    setEventStartT(event && event.eventStartingTime)
    setEventEndT(event && event.eventEndingTime)
    setEventVenue(event && event.eventVenue)
    setStop(true)
  }



  const { data, loading } = useQuery(QUERY_EVENT)

  const [deleteEvent] = useMutation(MUTATION_DELETE_EVENT, {
    refetchQueries: [
      QUERY_EVENT,
      "getAllEvent"
    ]
  })

  function handleDelete(id) {
    deleteEvent({
      variables: {
        "eventId": `${id}`
      }
    })
  }

  function handleEdit(event) {
    setStop(false)
    setEvent(event)
    handleShow()
  }

  const [updateEvent] = useMutation(MUTATION_UPDATE_EVENT, {
    refetchQueries: [
      QUERY_EVENT,
      "getAllEvent"
    ]
  })

  const [validated, setValidated] = useState(false);

  const handleSubmit = (event) => {
    const form = event.currentTarget;
    if (form.checkValidity() === false) {
      event.preventDefault();
      event.stopPropagation();
    } else {
      event.preventDefault();
      updateEvent({
        variables: {
          "updateEventInput": {
            "id": `${eventId}`,
            "eventTitle": `${eventTitle}`,
            "eventDescription": `${eventDes}`,
            "eventDate": `${evenDate}`,
            "eventStartingTime": `${eventStartT}`,
            "eventEndingTime": `${eventEndT}`,
            "eventVenue": `${eventVenue}`
          }
        }
      })
      handleClose()

    }
    setValidated(true);
  };

  console.log("data", data)
  return (
    <>
      <h5 style={{ marginTop: 10, fontFamily: "Dm sans", fontWeight: "bold" }}>All Contact</h5>
      {
        loading ?
          <Spinner animation="border" style={{ marginTop: 100 }} />
          :
          <>
            <Table bordered style={{ fontFamily: "Dm sans", fontSize: 12 }}>
              <thead>
                <tr>
                  <th>SR No.</th>
                  <th>Event Title</th>
                  <th>Event Date</th>
                  <th>Event Description</th>
                  <th>Event Start</th>
                  <th>Event End</th>
                  <th>Delete Event</th>
                  <th>Edit Event</th>
                </tr>
              </thead>
              <tbody>
                {
                  data && data.getAllEvent.slice().reverse().map(event => {
                    return (
                      <tr>
                        <td>{count++}</td>
                        <td>{event.eventTitle}</td>
                        <td><Moment format="DD/MM/YYYY">{event.eventDate}</Moment></td>
                        <td>{event.eventDescription}</td>
                        <td>{event.eventStartingTime}</td>
                        <td>{event.eventEndingTime}</td>
                        <td><Button variant="danger" size="sm" onClick={() => handleDelete(event.id)}><FiTrash2 style={{ marginTop: '-4px' }} /></Button></td>
                        <td><Button variant="success" size="sm" onClick={() => handleEdit(event)}><FiEdit style={{ marginTop: '-4px' }} /></Button></td>
                      </tr>
                    )
                  })
                }
              </tbody>
            </Table>
          </>
      }

      {
        data && data.getAllEvent.length === 0 ?
          <Container>
            <h1 style={{ fontFamily: 'Dm Sans', fontWeight: 'bold', textAlign: 'center', color: '#c0392b' }}>No Data Found</h1>
          </Container> : ''
      }

      <Modal show={show} onHide={handleClose} size="lg">
        <>
          <Container style={{ marginTop: 10 }}>
            <Row>
              <Col md={12}>
                <Form noValidate validated={validated} onSubmit={handleSubmit} style={{ marginTop: 30 }}>
                  <Row className="mb-3">
                    <Form.Group as={Col} md="4" controlId="validationCustom01">
                      <Form.Label style={{ fontFamily: "Dm sans", fontSize: 13 }}>Event Title</Form.Label>
                      <Form.Label style={{ fontFamily: "Dm sans", fontSize: 13 }}>{event && event.eventTitle}</Form.Label>
                      <Form.Control
                        required
                        type="text"
                        placeholder=" Event Title"
                        style={{ fontFamily: "Dm sans", fontSize: 13 }}
                        onChange={(e) => setEventTitle(e.target.value)}
                        value={eventTitle}
                      />
                    </Form.Group>
                    <Form.Group as={Col} md="8" controlId="validationCustom02">
                      <Form.Label style={{ fontFamily: "Dm sans", fontSize: 13 }}>Event Descrition</Form.Label>
                      <Form.Control
                        required
                        type="text"
                        placeholder="Event Description"
                        style={{ fontFamily: "Dm sans", fontSize: 13 }}
                        onChange={(e) => setEventDes(e.target.value)}
                        value={eventDes}
                      />
                    </Form.Group>
                  </Row>

                  <Row className="mb-3">
                    <Form.Group as={Col} md="4" controlId="validationCustom03">
                      <Form.Label style={{ fontFamily: "Dm sans", fontSize: 13 }}>Event Date</Form.Label>
                      <Form.Control type="date" placeholder="date" onChange={(e) => setEventDate(e.target.value)} required style={{ fontFamily: "Dm sans", fontSize: 13 }} value={evenDate}
                      />
                    </Form.Group>
                    <Form.Group as={Col} md="4" controlId="validationCustom04">
                      <Form.Label style={{ fontFamily: "Dm sans", fontSize: 13 }}>Event Start Time</Form.Label>
                      <Form.Control type="time" placeholder="Event Start Time" onChange={(e) => setEventStartT(e.target.value)} required style={{ fontFamily: "Dm sans", fontSize: 13 }} value={eventStartT} />
                    </Form.Group>
                    <Form.Group as={Col} md="4" controlId="validationCustom05" >
                      <Form.Label style={{ fontFamily: "Dm sans", fontSize: 13 }}>Event End Time</Form.Label >
                      <Form.Control type="time" placeholder="Event End Time" required onChange={(e) => setEventEndT(e.target.value)} style={{ fontFamily: "Dm sans", fontSize: 13 }} value={eventEndT} />
                    </Form.Group>
                  </Row>
                  <Row className="mb-3">
                    <Form.Group as={Col} md="4" controlId="validationCustom01">
                      <Form.Label style={{ fontFamily: "Dm sans", fontSize: 13 }}>Event Venue</Form.Label>
                      <Form.Control
                        required
                        type="text"
                        placeholder=" Event Title"
                        onChange={(e) => setEventVenue(e.target.value)}
                        style={{ fontFamily: "Dm sans", fontSize: 13 }}
                        value={eventVenue}
                      />
                    </Form.Group>
                  </Row>
                  <Button type="submit" style={{ fontFamily: "Dm sans", background: "#feca57", border: "2px solid #feca57", color: "#000", fontWeight: "800", marginBottom: 10 }}>Update Event</Button>
                </Form>
              </Col>
            </Row>
          </Container>
        </>
      </Modal>
    </>
  )
}
