
import React from 'react'
import { Container, Row, Col, Image, Carousel, Card } from 'react-bootstrap'
import e1 from '../Images/bg1.jpeg'
import eventimg from '../Images/big_75859_BOB-BRANCH.jpg'
import { FaCalendarAlt, FaClock, FaMap } from "react-icons/fa";
import { useQuery } from '@apollo/client';
import { QUERY_EVENT } from '../GraphQl/Query';
import { SRLWrapper } from "simple-react-lightbox";

export default function TopEventParties() {
    const { data } = useQuery(QUERY_EVENT)
    console.log(data)
    return (
        <>
            <Container >
                <Row>
                    <Col md={12} style={{ textAlign: 'center', marginTop: 50 }}>
                        <h2 style={{ color: 'black', fontWeight: 'bold', fontFamily: 'Kaushan Script' }} >
                            Today Events / Parties
                        </h2>
                        <h2 className='mx-auto d-block' style={{ backgroundColor: '#ffb606 ', height: 2, width: '25%', marginTop: 20 }}> </h2>
                        <Container>
                            <p style={{ marginTop: 20, color: ' #666666', fontWeight: 400, fontFamily: 'Poppins' }}>
                                For a special, unique, and personalized wedding experience in an incredible place, a luxurious banquet, or a beautiful city-hotel lawn, rely on Hotel Royal Palace. Our experienced wedding coordinators will assist you every step of the way to ensure your wedding is perfectly planned.
                            </p>
                        </Container>
                    </Col>
                </Row>
                <Card className='eventCard1'>
                    <Card.Body>
                        <Row>
                            {


                                <Col md={12}>
                                    <SRLWrapper>
                                        <Card className='eventCard1' style={{ marginTop: 20, width: '100%', height: '300px' }}>
                                            <Card.Body>
                                                <Row>
                                                    <Col md={6} style={{ paddingBottom: 25 }}>
                                                        <Carousel>
                                                            {
                                                                data && data.getAllEvent[0].eventImage.map(img => {

                                                                    return (
                                                                        <Carousel.Item>
                                                                            <img
                                                                                className="d-block w-100 img-fluid"
                                                                                src={`https://hotelroyalpalace.s3.amazonaws.com/${img}`}
                                                                                alt="First slide"
                                                                                style={{ margin: 10, height: 250 }}
                                                                            />
                                                                            <Carousel.Caption>

                                                                            </Carousel.Caption>
                                                                        </Carousel.Item>



                                                                    )



                                                                })




                                                            }

                                                        </Carousel>

                                                    </Col>

                                                    <Col md={6}>
                                                        <h5 style={{ color: '#002147', fontWeight: 'bold', fontFamily: 'Poppins' }}>
                                                            {data && data.getAllEvent[0].eventTitle}
                                                        </h5>
                                                        <Row>
                                                            <Col md={6}>
                                                                <p style={{ color: ' #666666', fontWeight: 600, fontFamily: 'Poppins', fontSize: 10 }}>
                                                                    <FaCalendarAlt style={{ marginTop: -4 }} /> {data && data.getAllEvent[0].eventDate}
                                                                </p>
                                                            </Col>
                                                            <Col md={6}>
                                                                <p style={{ color: ' #666666', fontWeight: 600, fontFamily: 'Poppins', fontSize: 10 }}>
                                                                    <FaClock style={{ marginTop: -4 }} />   {data && data.getAllEvent[0].eventStartingTime} AM -  {data && data.getAllEvent[0].eventEndingTime} PM </p>
                                                            </Col>
                                                        </Row>

                                                        <p style={{ color: ' #666666', fontWeight: 600, fontFamily: 'Poppins', fontSize: 12 }}>
                                                            <FaMap style={{ marginTop: -4 }} /> {data && data.getAllEvent[0].eventVenue}
                                                        </p>

                                                        <Col md={12} style={{marginTop: 30}}>
                                                            <Row style={{ backgroundColor: 'rgba(20, 23, 28, 0.1)', height: 2, marginBottom: 30 }}></Row>
                                                        </Col>
                                                        <p style={{ marginTop: 10, color: ' #666666', fontWeight: 400, fontFamily: 'Poppins' }}>
                                                            {data && data.getAllEvent[0].eventDescription}
                                                        </p>



                                                    </Col>
                                                </Row>
                                            </Card.Body>
                                        </Card>
                                    </SRLWrapper>

                                </Col>







                            }
                        </Row>
                    </Card.Body>
                </Card>
            </Container>
            <Container fluid style={{ marginTop: 50 }}>
                <Row style={{ backgroundColor: '#dfe6e9' }}>
                    <h2 style={{ textAlign: 'center', color: 'black', fontWeight: 'bold', marginTop: 30, marginBottom: 30, fontFamily: 'Poppins' }}> For Any Query Please Contact Us!!</h2>
                </Row>
            </Container>

        </>

    )
}


