import React from 'react'
import Navigation from "../Component/Navigation";
import Header from '../Component/Header'
import AboutUs from "../Component/AboutUs";
import RoomDetailsSlick from "../Component/RoomDetailsSlick";
import OurServices from "../Component/OurServices";
import TopEventParties from "../Component/TopEventParties";
import OtherServices from "../Component/OtherServices"
import Testimonials from "../Component/Testimonials";
import Footer from '../Component/Footer'
import Contactus from "../Component/Contactus";

export default function Home() {
  return (
    <>
      <Navigation />
      <Header />
      <AboutUs />
      <RoomDetailsSlick />
      <OurServices />
      <TopEventParties />
      <OtherServices />
      <Testimonials />
      <Contactus />
      <Footer />

    </>
  )
}
