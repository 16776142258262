import { useQuery } from '@apollo/client';
import React from 'react'
import { Table, Spinner, Container } from "react-bootstrap"
import { QUERY_CONTACT } from '../GraphQl/Query';
import Moment from 'react-moment'

export default function ViewContact() {

  let count = 1;
  const { data, loading } = useQuery(QUERY_CONTACT)
  console.log("data", data)
  return (
    <>
      <h5 style={{ marginTop: 10, fontFamily: "Dm sans", fontWeight: "bold" }}>All Contact</h5>

      {
        loading ?
          <Spinner animation="border" style={{ marginTop: 100 }} />
          :
          <>

            <Table bordered style={{ fontFamily: "Dm sans", fontSize: 12 }}>
              <thead>
                <tr>
                  <th>SR No.</th>
                  <th>Name</th>
                  <th>Email</th>
                  <th>Contact</th>
                  <th>Message</th>
                  <th>Date/Time</th>
                </tr>
              </thead>
              <tbody>
                {
                  data && data.getAllContact.slice().reverse().map(contact => {
                    return (
                      <tr>
                        <td>{count++}</td>
                        <td>{contact.fullName}</td>
                        <td>{contact.email}</td>
                        <td>{contact.contact}</td>
                        <td>{contact.message}</td>
                        <td><Moment format="DD/MM/YYYY, hh:mm A">{contact.createDateTime}</Moment></td>
                      </tr>
                    )
                  })
                }
              </tbody>
            </Table>
            {
              data && data.getAllContact.length === 0 ?
                <Container>
                  <h1 style={{ fontFamily: 'Dm Sans', fontWeight: 'bold', textAlign: 'center', color: '#c0392b' }}>No Data Found</h1>
                </Container> : ''
            }
          </>
      }

    </>
  )
}
