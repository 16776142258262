import { gql } from '@apollo/client';



export const QUERY_CARRIER =gql`
query GetAllCarrier {
    getAllCarrier {
      id
      fName
      lName
      fullAddress
      contact
      email
      experience
      resumeUpload
      createDateTime
      status
    }
  }

`

export const QUERY_CONTACT =gql`
query GetAllContact {
    getAllContact {
      id
      fullName
      email
      contact
      message
      createDateTime
      status
    }
  }
`

export const QUERY_ROOM =gql`
query Query {
    getAllRoom {
      id
      roomName
      roomNo
      roomType
      roomDiscription
      roomImg
      facility
      feature
      service
      price
      availability
      createDateTime
      status
    }
  }
`

export const QUERY_ROOM_BY_ID =gql`
query Query($roomId: ID) {
  
    getRoomById(roomId: $roomId) {
      id
      roomName
      roomNo
      roomType
      roomDiscription
      facility
      feature
      service
      price
      availability
      createDateTime
      status
      roomImg
    }
  }


`

export const QUERY_EVENT =gql`
query GetAllEvent {
  getAllEvent {
    id
    eventTitle
    eventDescription
    eventDate
    eventStartingTime
    eventEndingTime
    eventVenue
    eventImage
    createDateTime
    status
  }
}

`

export const QUERY_GET_ROOM_ID =gql`
query Query($roomId: ID) {
  getRoomById(roomId: $roomId) {
    id
    roomName
    roomNo
    roomType
    roomDiscription
    roomImg
    facility
    feature
    service
    price
    availability
    createDateTime
    status
  }
}

`

export const QUERY_BOOKING =gql`
query GetBooking {
  getBooking {
    id
    roomName
    roomNo
    roomType
    roomId
    roomPrice
    userFname
    userLname
    userContact
    userEmail
    userAddress
    userCity
    userState
    userPincode
    createdDateTime
    status
  }
}



`