import { useMutation } from '@apollo/client';
import React, { useState } from 'react'
import { Container, Row, Col, Form, Spinner, Button, Image } from 'react-bootstrap'
import { MUTATION_CARRIER } from '../GraphQl/Mutation';
import { QUERY_CARRIER } from '../GraphQl/Query';
import swal from "sweetalert";
import AWS from "aws-sdk";
import uniqid from "uniqid";
import Navigation from './Navigation'
const ID = "AKIA6GB4RFKTDTDA6E2O";
const SECRET = "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";
const BUCKET_NAME = "byaahlagan-profile-image";

const s3 = new AWS.S3({
  accessKeyId: ID,
  secretAccessKey: SECRET,
});


export default function Career() {

  const [validated, setValidated] = useState(false);
  const [spin, setSpin] = useState(false)

  const [createCareer] = useMutation(MUTATION_CARRIER, {
    refetchQueries: [
      QUERY_CARRIER
    ]
  })

  const [fName, setFName] = useState('')
  const [lName, setLName] = useState('')
  const [email, setEmail] = useState('')
  const [contact, setContact] = useState('')
  const [address, setAddress] = useState('')
  const [experience, setExperience] = useState('')
  const [file, setFile] = useState('')

  const handleSubmit = (event) => {
    const form = event.currentTarget;
    if (form.checkValidity() === false) {
      setValidated(true);
      event.preventDefault();
      event.stopPropagation();
    } else {
      setSpin(true)
      event.preventDefault();
      setValidated(false);
      try {
        const uniqueId = uniqid()
        var uniqueFileName = uniqueId + "-" + file.name;
        const params = {
          Bucket: BUCKET_NAME,
          Key: uniqueFileName,
          Body: file,
        };
        s3.upload(params, function (err, data) {
          if (err) {
            throw err;
          }
          else {
            event.preventDefault();
            createCareer({
              variables: {
                "Career Input": {
                  "fName": `${fName}`,
                  "lName": `${lName}`,
                  "fullAddress": `${address}`,
                  "email": `${email}`,
                  "contact": `${contact}`,
                  "experience": `${experience}`,
                  "resumeUpload": `${uniqueFileName}`
                }
              }
            })
            setFName('')
            setLName('')
            setEmail('')
            setContact('')
            setAddress('')
            setExperience('')
            setFile('')
            swal({
              title: "Career  Request Sent",
              text: "We will contact you as soon as possible!!!",
              icon: "success",
            })
            setSpin(false)
          }
        });
      }
      catch (err) {
        console.log(err);
      }
    }

  };



  return (
    <>
      <Navigation />
      <Container style={{ marginTop: 120, border: "2px solid #fbc531", borderRadius: 10 }}>
        <Row>
          <Col md={6}>
            <Image src="https://img.freepik.com/free-vector/career-progress-concept-illustration_114360-3348.jpg?w=740&t=st=1663161374~exp=1663161974~hmac=7e4658bdf45fe447a51888e54b0ecc6b610b7062511e53ceaa6fc12ec60a5769" style={{ width: "80%", marginTop: 30 }} className="mx-auto d-block" />
          </Col>
          <Col md={6}>
            <h4 style={{ fontFamily: "Dm sans", marginTop: 90, marginBottom: 20, textAlign: "center", fontWeight: "800", fontSize: "20px" }}>Start Your Career With Hotel Royal Palace</h4>

            <Form noValidate validated={validated} onSubmit={handleSubmit}>
              <Row className="mb-3">
                <Form.Group as={Col} md="6" controlId="validationCustom01">
                  <Form.Control
                    required
                    type="text"
                    placeholder="First name"
                    onChange={(e) => setFName(e.target.value)}
                    value={fName}
                    style={{ border: "2px solid #000", fontFamily: "Dm sans", fontSize: 13 }}
                  />
                </Form.Group>

                <Form.Group as={Col} md="6" controlId="validationCustom02">
                  <Form.Control
                    required
                    type="text"
                    placeholder="Last name"
                    onChange={(e) => setLName(e.target.value)}
                    value={lName}
                    style={{ border: "2px solid #000", fontFamily: "Dm sans", fontSize: 13 }}
                  />
                </Form.Group>
              </Row>

              <Row className="mb-3">
                <Form.Group as={Col} md="6" controlId="validationCustom01">
                  <Form.Control
                    required
                    type="email"
                    placeholder=" Enter Email"
                    onChange={(e) => setEmail(e.target.value)}
                    value={email}
                    style={{ border: "2px solid #000", fontFamily: "Dm sans", fontSize: 13 }}
                  />
                </Form.Group>

                <Form.Group as={Col} md="6" controlId="validationCustom02">
                  <Form.Control
                    required
                    type="number"
                    placeholder="Enter Contact"
                    onChange={(e) => setContact(e.target.value)}
                    value={contact}
                    style={{ border: "2px solid #000", fontFamily: "Dm sans", fontSize: 13 }}
                  />
                </Form.Group>
              </Row>

              <Row className="mb-3">
                <Form.Group as={Col} md="6" controlId="validationCustom01">
                  <Form.Control
                    required
                    as="textarea"
                    rows={1}
                    placeholder=" Enter Address"
                    onChange={(e) => setAddress(e.target.value)}
                    value={address}
                    style={{ border: "2px solid #000", fontFamily: "Dm sans", fontSize: 13 }}
                  />
                </Form.Group>

                <Form.Group as={Col} md="6" controlId="validationCustom02">
                  <Form.Control
                    required
                    type="number"
                    placeholder="Enter Experience (In Year)"
                    onChange={(e) => setExperience(e.target.value)}
                    style={{ border: "2px solid #000", fontFamily: "Dm sans", fontSize: 13 }}
                    value={experience}
                  />
                </Form.Group>
              </Row>

              <Row className="mb-3">
                <Form.Group as={Col} md="12" controlId="validationCustom01">
                  <Form.Label style={{ fontFamily: "Dm sans", fontWeight: "800" }}>Upload Resume <span style={{ fontSize: '12px' }}>(PDF, JPG, PNG)</span></Form.Label>
                  <Form.Control
                    type="file"
                    required
                    name="file"
                    onChange={(e) => setFile(e.target.files[0])}
                    style={{ border: "2px solid #000", fontSize: 13 }}
                  />
                </Form.Group>
              </Row>
              {
                spin ?
                  <Spinner animation="border" role="status">
                    <span className="visually-hidden">Loading...</span>
                  </Spinner> :
                  <Button type="submit" style={{ fontWeight: "600", marginBottom: 50, border: "2px solid #fbc531", background: "#fbc531", color: "#000" }} className="mx-auto d-block"  >Submit Form</Button>
              }
            </Form>
          </Col>
        </Row>
      </Container>
    </>
  )
}
