import React, { useState } from 'react'
import { Container, Row, Col, Image, Form, Button, Spinner } from 'react-bootstrap'
import Navigation from './Navigation'
import logo from "../Images/admin.png"
import { useMutation } from '@apollo/client';
import { MUTATION_ADMIN_LOGIN } from '../GraphQl/Mutation';
import swal from 'sweetalert';
import { useNavigate } from 'react-router-dom'
import { Navigate } from 'react-router-dom';

export default function Adminlogin() {
  const navigater = useNavigate()

  const adminToken = localStorage.getItem('adminToken')
  if (adminToken) {
    navigater("/adminDashboard/addRoom")
  }
  const [validated, setValidated] = useState(false);

  const [userN, setUserN] = useState("")
  const [password, setPassword] = useState("")

  const [loginAdmin, { data, error, loading }] = useMutation(MUTATION_ADMIN_LOGIN)

  const handleSubmit = (event) => {
    const form = event.currentTarget;
    if (form.checkValidity() === false) {
      event.preventDefault();
      event.stopPropagation();
    } else {
      event.preventDefault();
      loginAdmin({
        variables: {
          "userName": `${userN}`,
          "password": `${password}`
        }
      })
    }
    setValidated(true);
  };

  if (data) {
    localStorage.setItem("adminToken", data.loginAdmin.adminToken)
    localStorage.setItem("adminId", data.loginAdmin.adminId)
    navigater("/adminDashboard/addRoom")
  }


  return (
    <>
      <Navigation />
      <Container style={{ marginTop: 120, border: "2px solid #fbc531", borderRadius: 10 }}  >
        <Row>
          <Col md={6}>
            <Image src={logo} style={{ width: "100%" }} className="mx-auto d-block" />
          </Col>
          <Col md={6}>
            <h4 style={{ textAlign: "center", marginTop: 100, marginBottom: 20, fontFamily: "Dm sans", fontWeight: "800" }}>Admin Login</h4>
            <Form noValidate validated={validated} onSubmit={handleSubmit} style={{ marginBottom: 20 }}>
              {
                error ?
                  <p style={{ color: "red", fontFamily: "Dm sans" }}>Username & Password not match!!!</p>
                  :
                  <></>
              }
              <Row className="mb-3">
                <Form.Group as={Col} md="9" controlId="validationCustom01" className="mx-auto d-block">
                  <Form.Control
                    required
                    type="text"
                    placeholder="Enter Username"
                    style={{ borderRadius: 50, border: "2px solid #000", fontFamily: "Dm sans" }}
                    onChange={(e) => setUserN(e.target.value)}
                  />
                </Form.Group>
              </Row>

              <Row className="mb-3">
                <Form.Group as={Col} md="9" controlId="validationCustom01" className="mx-auto d-block">
                  <Form.Control
                    required
                    type="password"
                    placeholder="Enater Password"
                    style={{ borderRadius: 50, border: "2px solid #000" }}
                    onChange={(e) => setPassword(e.target.value)}
                  />
                </Form.Group>
              </Row>
              {
                loading ?
                  <Spinner animation="border" role="status"></Spinner> :
                  <Button type="submit" style={{ width: "200px", backgroundColor: "#fbc531", border: "2px solid #fbc531", color: "#000", fontWeight: "500" }} className="mx-auto d-block">Login</Button>
              }
            </Form>
          </Col>
        </Row>
      </Container>
    </>
  )
}
