import Home from "./Component/Home";
import { Routes, Route, Link } from "react-router-dom";
import Adminlogin from "./Component/Adminlogin";
import Carrier from "./Component/Carrier";
import AdminDashboard from "./Admin/AdminDashboard";
import Room from "./Component/Room"
import Event from "./Component/Event";
import Booking from "./Component/Booking";
import AddRoom from "./Admin/AddRoom";
import AddEvent from "./Admin/AddEvent";
import AllRoom from "./Admin/AllRoom";
import AllEvent from "./Admin/AllEvent";
import AllContact from "./Admin/ViewContact";
import AllCarrier from "./Admin/ViewCarrier";
import AllBookingRoom from "./Admin/AllBookingRoom";

import Down from "./Down";

import "./App.css"


function App() {
  return (
    <div className="App">

      <Routes>
        {/* <Route path="/" exact={true} element={<Down />} /> */}
        <Route path="/" exact={true} element={<Home />} />
        <Route path="*" element={<Pagenotfound />} />
        <Route path="/admin" element={<Adminlogin />} />
        <Route path="/carrier" element={<Carrier />} />

        <Route path="/adminDashboard" element={<AdminDashboard />}>
          <Route path="addRoom" element={<AddRoom />} />
          <Route path="addEvent" element={<AddEvent />} />
          <Route path="allContact" element={<AllContact />} />
          <Route path="allCarrier" element={<AllCarrier />} />
          <Route path="allRoom" element={<AllRoom />} />
          <Route path="allEvent" element={<AllEvent />} />
          <Route path="AllBookingRoom" element={<AllBookingRoom />} />
        </Route>

        <Route path="/room" element={<Room />} />
        <Route path="/event" element={<Event />} />
        <Route path="/Booking/:id"  element={<Booking />} />
     
      </Routes>
    </div>
  );
}

function Pagenotfound() {
  return (
    <div>
      <h1> 404 page not found</h1>
      <h5>
        <Link to="/">Home</Link>
      </h5>
    </div>
  )
}

export default App;
