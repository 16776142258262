
import React from 'react'
import { Container, Row, Col, Image, Button, Card } from 'react-bootstrap'
import e3 from '../Images/bg.jpeg'
import e4 from '../Images/DSCN5674.JPG'
import e5 from '../Images/friends-laughing-playground.jpg'
import '../ComponentsCss/OurServices.css'
export default function OtherServices() {
    return (
        <Container fluid >
            <Row>
                <Col md={12} style={{ textAlign: 'center', marginTop: 50 }}>
                    <h2 style={{ color: 'black', fontWeight: 'bold', fontFamily: 'Kaushan Script' }} >
                        Enjoy Our Services

                    </h2>
                    <h2 className='mx-auto d-block' style={{ backgroundColor: '#ffb606 ', height: 2, width: '15%', marginTop: 20 }}> </h2>
                    <Container>
                        <p style={{ marginTop: 20, color: ' #666666', fontWeight: 400, fontFamily: 'Poppins' }}>
                            Our elegant hotel offers a combination of modern amenities along with flawless hospitality and care. We cater to both leisure as well as corporate clients. Book with us and experience comfort like never before.
                        </p>
                    </Container>
                </Col>
            </Row>
            <Row>
                <Col md={4} style={{ paddingBottom: 25 }}>
                    <Image src={e4} className='img-fluid' style={{ height: 300, width: '100%' }} />
                    <h3 style={{ color: '#002147', fontWeight: 'bold', marginTop: 10, fontFamily: 'Poppins' }}>
                        Bar
                    </h3>
                </Col>
                <Col md={4}>
                    <Image src={e3} className='img-fluid' style={{ height: 300, width: '100%' }} />
                    <h3 style={{ color: '#002147', fontWeight: 'bold', marginTop: 10, fontFamily: 'Poppins' }}>
                        Swimming Pool
                    </h3>
                </Col>
                <Col md={4}>
                    <Image src={e5} className='img-fluid' style={{ height: 300, width: '100%' }} />
                    <h3 style={{ color: '#002147', fontWeight: 'bold', marginTop: 10, fontFamily: 'Poppins' }}>
                        Kids Zone.
                    </h3>
                </Col>
            </Row>
        </Container>
    )
}

