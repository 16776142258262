
import React from 'react'
import { Container, Row, Col, Card, Image, Button, Carousel } from 'react-bootstrap'
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import '../ComponentsCss/Testimonials.css'
import cardpic from '../Images/DSCN5585.JPG'
import t1 from '../Images/shivam.jpg'
import t2 from '../Images/vishaln.jpg'
import t3 from '../Images/vipin.jpg'
import t4 from '../Images/my.jpg'




export default function SliderTestimonials() {
    const settings = {

        className: "center",
        infinite: true,
        slidesToShow: 2,
        swipeToSlide: true,
        autoplay: true,
        autoplaySpeed: 2000,

        afterChange: function (index) {

        },
        responsive: [
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: 3,
                    slidesToScroll: 3,
                    infinite: true,
                    dots: true
                }
            },
            {
                breakpoint: 600,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 2,
                    initialSlide: 2
                }
            },
            {
                breakpoint: 480,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1
                }
            }
        ]
    };
    return (
        <div>
            <Container  >
                <Row>
                    <Col md={12}>
                        <Container style={{ marginTop: '30px', marginBottom: 50 }}>
                            <Row style={{ marginTop: '10px', marginLeft: '5px', marginRight: '5px' }}>
                                <Slider {...settings}>
                                    <div>
                                        <Card className='SLiderCard' style={{ backgroundColor: 'transparent', width: '95%', height: '220px' }} >
                                            <Card.Body>
                                                <Row>
                                                    <Col md={5}>
                                                        <Image src={t1} className='img-fluid mx-auto d-block cardImg' style={{ width: '100%', height: '190px' }} />
                                                    </Col>
                                                    <Col md={7}>
                                                        <p className='textonCard' style={{ color: '#666666', fontWeight: 550, fontSize: 15, fontFamily: 'Poppins' }}>
                                                            Always an outstanding, quality experience. Great deal too! Doormen are the best in the city. Great location for a meeting. Quiet sleep. Great bar and cocktails.
                                                        </p>
                                                        <Row>
                                                            <p className='textonCard' style={{ color: 'black', fontWeight: 750, fontSize: 20, fontFamily: 'Poppins' }}>Shivam Seth</p>
                                                        </Row>
                                                    </Col>
                                                </Row>
                                            </Card.Body>
                                        </Card>

                                    </div>
                                    <div>
                                        <Card className='SLiderCard' style={{ backgroundColor: 'transparent', width: '95%', height: '220px' }} >
                                            <Card.Body>
                                                <Row>
                                                    <Col md={5}>
                                                        <Image src={t2} className='img-fluid mx-auto d-block cardImg' style={{ width: '100%', height: '190px' }} />
                                                    </Col>
                                                    <Col md={7}>
                                                        <p className='textonCard' style={{ color: '#666666', fontWeight: 550, fontSize: 15, fontFamily: 'Poppins' }}>
                                                            Spacious hotel with multiple room choices. Good Family accommodation options. Vegetarian restaurant on the premises with good service.
                                                        </p>
                                                        <Row>
                                                            <p className='textonCard' style={{ color: 'black', fontWeight: 750, fontSize: 20, fontFamily: 'Poppins' }}>Vishal Singh</p>
                                                        </Row>
                                                    </Col>
                                                </Row>
                                            </Card.Body>
                                        </Card>
                                    </div>
                                    <div>
                                        <Card className='SLiderCard' style={{ backgroundColor: 'transparent', width: '95%', height: '220px' }} >
                                            <Card.Body>
                                                <Row>
                                                    <Col md={5}>
                                                        <Image src={t3} className='img-fluid mx-auto d-block cardImg' style={{ width: '100%', height: '190px' }} />
                                                    </Col>
                                                    <Col md={7}>
                                                        <p className='textonCard' style={{ color: '#666666', fontWeight: 550, fontSize: 15, fontFamily: 'Poppins' }}>
                                                            So nice hotel..its really nice place I enjoyed everything nature...room was very nice clean and service was also good.
                                                        </p>
                                                        <Row>
                                                            <p className='textonCard' style={{ color: 'black', fontWeight: 750, fontSize: 20, fontFamily: 'Poppins' }}>Vipin Rai</p>

                                                        </Row>
                                                    </Col>
                                                </Row>
                                            </Card.Body>
                                        </Card>
                                    </div>
                                    <div>
                                        <Card className='SLiderCard' style={{ backgroundColor: 'transparent', width: '95%', height: '220px' }} >
                                            <Card.Body>
                                                <Row>
                                                    <Col md={5}>
                                                        <Image src={t4} className='img-fluid mx-auto d-block cardImg' style={{ width: '100%', height: '190px' }} />
                                                    </Col>
                                                    <Col md={7}>
                                                        <p className='textonCard' style={{ color: '#666666', fontWeight: 550, fontSize: 15, fontFamily: 'Poppins' }}>
                                                            Thank you for everything—All the Hotel Monteleone staff are amazing and we really appreciate the help and level of service we received with every request and interaction.
                                                        </p>
                                                        <Row>
                                                            <p className='textonCard' style={{ color: 'black', fontWeight: 750, fontSize: 20, fontFamily: 'Poppins' }}>Shrikant Dwivedi</p>
                                                        </Row>
                                                    </Col>
                                                </Row>
                                            </Card.Body>
                                        </Card>
                                    </div>
                                </Slider>
                            </Row>

                        </Container>
                    </Col>
                </Row>

            </Container>

        </div>
    )
}