import React from 'react'
import Navigation from '../Component/Navigation'
import { Tab, Row, Col, Nav, } from "react-bootstrap"
import { FaHome, FaPlusCircle, FaBullhorn, FaBriefcase, FaAddressBook ,FaBookmark } from "react-icons/fa";

import { useNavigate, Route, Outlet, Link, Switch } from 'react-router-dom'


export default function AdminDashboard() {
  return (
    <>
      <Navigation />
      <br></br><br></br><br></br><br></br>
      <h4 style={{ textAlign: "center", fontFamily: "Dm sans", background: "#000", padding: 15, color: "#fff" }}>Admin Dashboard</h4>
      <Tab.Container id="left-tabs-example" defaultActiveKey="first"  >
        <Row style={{ marginTop: -10 }}>
          <Col sm={2} style={{ background: "#000", height: 500 }} >
            <Nav variant="pills" className="flex-column">
              <Nav.Item>
                <Nav.Link as={Link} to={'/adminDashboard/addRoom'} style={{ color: "#fff", fontFamily: "Dm sans", textAlign: 'left' }}><FaPlusCircle style={{ marginTop: "-4px" }} /> Add Room</Nav.Link>
              </Nav.Item>
              <Nav.Item>
                <Nav.Link as={Link} to={'/adminDashboard/allRoom'} style={{ fontFamily: "Dm sans", color: "#fff", textAlign: 'left' }}><FaHome style={{ marginTop: "-4px" }} /> All Room</Nav.Link>
              </Nav.Item>
              <Nav.Item>
                <Nav.Link as={Link} to={'/adminDashboard/addEvent'} style={{ fontFamily: "Dm sans", color: "#fff", textAlign: 'left' }}><FaPlusCircle style={{ marginTop: "-4px" }} /> Add Event</Nav.Link>
              </Nav.Item>
              <Nav.Item>
                <Nav.Link as={Link} to={'/adminDashboard/allEvent'} style={{ fontFamily: "Dm sans", color: "#fff", textAlign: 'left' }}><FaBullhorn style={{ marginTop: "-4px" }} /> All Event</Nav.Link>
              </Nav.Item>
              <Nav.Item>
                <Nav.Link as={Link} to={'/adminDashboard/allCarrier '} style={{ fontFamily: "Dm sans", color: "#fff", textAlign: 'left' }}><FaBriefcase style={{ marginTop: "-4px" }} /> All Career </Nav.Link>
              </Nav.Item>
              <Nav.Item>
                <Nav.Link as={Link} to={'/adminDashboard/allContact'} style={{ fontFamily: "Dm sans", color: "#fff", textAlign: 'left' }}><FaAddressBook style={{ marginTop: "-4px" }} /> All Contact</Nav.Link>
              </Nav.Item>

              <Nav.Item>
                <Nav.Link as={Link} to={'/adminDashboard/AllBookingRoom'} style={{ fontFamily: "Dm sans", color: "#fff", textAlign: 'left' }}><FaBookmark style={{ marginTop: "-4px" }} /> All Booking</Nav.Link>
              </Nav.Item>
            </Nav>
          </Col>
          <Col sm={10} style={{ background: "#ecf0f1" }}>
            <Outlet />
          </Col>
        </Row>
      </Tab.Container>

    </>
  )
}
