
import React from 'react'
import { Container, Row, Col, Card, Image } from 'react-bootstrap'
import '../ComponentsCss/Testimonials.css'
import SliderTestimonials from './SliderTestimonials'

export default function Testimonials() {
    return (
        <Container >
            <Row>
                <Col md={12} style={{ textAlign: 'center', marginTop: 50 }}>
                    <h2 style={{ color: 'black', fontWeight: 'bold', fontFamily: 'Kaushan Script' }} >
                        Some Reviews
                    </h2>
                    <h2 className='mx-auto d-block' style={{ backgroundColor: '#ffb606 ', height: 2, width: '15%', marginTop: 20 }}> </h2>
                    <Container>
                        <Row>
                            <Col md={1}>
                            </Col>
                            <Col md={10}>
                                <p style={{ marginTop: 20, color: '#666666', fontWeight: 400, fontFamily: 'Poppins' }}>
                                    Read some of our latest guest testimonials and reviews about the hospitality service and comfort stay at the Hotel Royal Palace.
                                </p>
                            </Col>
                            <Col md={1}>
                            </Col>
                        </Row>
                    </Container>
                </Col>
            </Row>

            <SliderTestimonials />

        </Container>
    )
}