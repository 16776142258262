import { gql } from '@apollo/client';
import { FaGlideG } from 'react-icons/fa';


export const MUTATION_ADMIN_LOGIN =gql`
mutation Mutation($userName: String, $password: String) {
    loginAdmin(userName: $userName, password: $password) {
      adminId
      adminToken
    }
  }

`

export const MUTATION_CONTACT =gql`
mutation Mutation($contactInput: contactInput) {
    createContact(ContactInput: $contactInput) {
      id
     
    }
  }

`

export const MUTATION_CARRIER =gql`
mutation Mutation($carrierInput: carrierInput) {
    createCarrier(CarrierInput: $carrierInput) {
      id
      
    }
  }

`

export const MUTATION_EVENT =gql`
mutation Mutation($eventInput: eventInput) {
  createEvent(EventInput: $eventInput) {
    id
    
  }
}

`
export const MUTATION_ROOM =gql`
mutation CreateRoom($roomInput: roomInput) {
  createRoom(RoomInput: $roomInput) {
    id
    
  }
}

`
export const MUTATION_DELETE_EVENT =gql`
mutation Mutation($eventId: ID) {
  deleteEvent(eventId: $eventId) {
    id
    
  }
}


`

export const MUTATION_UPDATE_EVENT =gql`
mutation Mutation($updateEventInput: updateEventInput) {
  updateEvent(UpdateEventInput: $updateEventInput) {
    id
   
  }
}

`

export const MUTATION_UPDATE_ROOM =gql`
mutation UpdateRoom($updateRoomInput: updateRoomInput) {
  updateRoom(UpdateRoomInput: $updateRoomInput) {
    id
   
  }
}

`
export const MUTATION_DELETE_ROOM =gql`
mutation DeleteRoom($roomId: ID) {
  deleteRoom(roomId: $roomId) {
    id
    
   
  }
}


`

export const MUTATION_BOOOKING =gql`
mutation Mutation($bookingInput: bookingInput) {
  createBooking(BookingInput: $bookingInput) {
    id
     
  }
}
`