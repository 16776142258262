import React from 'react'
import { Container, Row, Col, Image, Button, Card, Carousel } from 'react-bootstrap'
import e1 from '../Images/bg1.jpeg'
import eventimg from '../Images/big_75859_BOB-BRANCH.jpg'
import { FaCalendarAlt, FaClock, FaMap } from "react-icons/fa";
import Navigation from './Navigation';
import { useQuery } from '@apollo/client';
import { QUERY_EVENT } from '../GraphQl/Query';
import { SRLWrapper } from "simple-react-lightbox";
export default function Event() {

    const { data } = useQuery(QUERY_EVENT)

    console.log(data)

    return (
        <>
            <Navigation />

            <Container>
                <br></br> <br></br> <br></br> <br></br> <br></br>

                <Row>
                    {
                        data && data.getAllEvent.slice().reverse().map(event => {
                            return (

                                <Col md={6}>
                                    <SRLWrapper>
                                        <Card className='eventCard1' style={{ marginTop: 20, width: '100%', height: '300px' }}>
                                            <Card.Body>
                                                <Row>
                                                    <Col md={6} style={{ paddingBottom: 25 }}>
                                                        <Carousel>
                                                            {
                                                                event.eventImage.map(img => {

                                                                    return (
                                                                        <Carousel.Item>
                                                                            <img
                                                                                className="d-block w-100 img-fluid"
                                                                                src={`https://hotelroyalpalace.s3.amazonaws.com/${img}`}
                                                                                alt="First slide"
                                                                                style={{ margin: 10, height: 250 }}
                                                                            />
                                                                            <Carousel.Caption>

                                                                            </Carousel.Caption>
                                                                        </Carousel.Item>



                                                                    )



                                                                })




                                                            }

                                                        </Carousel>

                                                    </Col>

                                                    <Col md={6}>
                                                        <h5 style={{ color: '#002147', fontWeight: 'bold', fontFamily: 'Poppins' }}>
                                                            {event.eventTitle}
                                                        </h5>

                                                        <Row>
                                                            <Col md={6}>
                                                                <p style={{ color: ' #666666', fontWeight: 600, fontFamily: 'Poppins', fontSize: 10 }}>
                                                                    <FaCalendarAlt style={{ marginTop: -4 }} /> {event.eventDate}
                                                                </p>
                                                            </Col>
                                                            <Col md={6}>
                                                                <p style={{ color: ' #666666', fontWeight: 600, fontFamily: 'Poppins', fontSize: 10 }}>
                                                                    <FaClock style={{ marginTop: -4 }} />   {event.eventStartingTime} AM -  {event.eventEndingTime} PM </p>
                                                            </Col>

                                                        </Row>

                                                        <p style={{ color: ' #666666', fontWeight: 600, fontFamily: 'Poppins', fontSize: 12 }}>
                                                            <FaMap style={{ marginTop: -4 }} /> {event.eventVenue
                                                            }
                                                        </p>

                                                        <Col md={12} style={{

                                                            marginTop: 30
                                                        }}>
                                                            <Row style={{
                                                                backgroundColor: 'rgba(20, 23, 28, 0.1)',
                                                                height: 2,
                                                                marginBottom: 30
                                                            }}>

                                                            </Row>

                                                        </Col>
                                                        <p style={{ marginTop: 10, color: ' #666666', fontWeight: 400, fontFamily: 'Poppins' }}>

                                                            {event.eventDescription}
                                                        </p>



                                                    </Col>
                                                </Row>
                                            </Card.Body>
                                        </Card>
                                    </SRLWrapper>

                                </Col>
                            )


                        })



                    }


                </Row>

            </Container>



        </>
    )
}
