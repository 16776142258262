import React, { useState } from 'react'
import { Container, Col, Row, Button, Form, InputGroup, Modal, Spinner, Image } from 'react-bootstrap'
import AWS from "aws-sdk";
import Resizer from "react-image-file-resizer";
import uniqid from "uniqid";
import Dropzone from "react-dropzone-uploader";
import Img from "../Images/AcRoom.jpg"
import swal from "sweetalert";
import { useMutation } from '@apollo/client';
import { MUTATION_ROOM } from '../GraphQl/Mutation';
import { QUERY_ROOM } from '../GraphQl/Query';

import Multiselect from 'multiselect-react-dropdown';
import ReactTagInput from "@pathofdev/react-tag-input";
import "@pathofdev/react-tag-input/build/index.css";

const ID = "AKIA6GB4RFKTM63VVHEK";
const SECRET = "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";
const BUCKET_NAME = "hotelroyalpalace";


const s3 = new AWS.S3({
  accessKeyId: ID,
  secretAccessKey: SECRET,
});

const imageKey = []
export default function AddRoom() {

  const [validated, setValidated] = useState(false);

  const [roomN, setRoomN] = useState('')
  const [roomNo, setRoomNo] = useState('')
  const [roomDes, setRoomDes] = useState('')
  const [roomType, setRoomType] = useState('')
  const [tags, setTags] = React.useState([""])
  const [servicetags, setServiceTags] = React.useState([""])
  const [featuretags, setFeatureTags] = React.useState([""])
  const [price, setPrice] = useState('')

  const [imageBox, setImageBox] = useState()
  const [uploadDone, setUploadDone] = useState(false)
  const [uploadLoading, setUploadLoading] = useState(false)
  const [selectImage, setSelectImage] = useState()
  const [multipleImgKey, setMultipleImgKey] = useState([])


  function multipleImg(e) {
    setSelectImage([...e.target.files])
  }

  const [createRoom] = useMutation(MUTATION_ROOM, {
    refetchQueries: [
      QUERY_ROOM
    ]
  })

  const handleSubmit = (event) => {
    const form = event.currentTarget;
    if (form.checkValidity() === false) {
      setValidated(true);
      event.preventDefault();
      event.stopPropagation();
    } else {

      const removeFacultyElement = tags.filter(function (item) {
        return item !== ''
      })

      const removeServiceElement = servicetags.filter(function (item) {
        return item !== ''
      })

      const removeFeatureElement = featuretags.filter(function (item) {
        return item !== ''
      })

      const strFaculty = removeFacultyElement.join(',');
      const strService = removeServiceElement.join(',');
      const strFeature = removeFeatureElement.join(',');

      setUploadLoading(true)
      setValidated(false);
      event.preventDefault();
      try {
        selectImage.map(async (fileData) => {
          const uniqueId = uniqid();
          var regex = new RegExp('[^.]+$');
          var extension = fileData.name.match(regex);
          var uniqueFileName = "HotelImg" + '-' + uniqueId + '-' + uniqueId + '.' + extension[0];
          imageKey.push(uniqueFileName)
          const params = {
            Bucket: BUCKET_NAME,
            Key: uniqueFileName,
            Body: fileData,
          };
          s3.upload(params, function (err, data) {
            if (err) {
              throw err;
            } else {
              setImageBox(uniqueFileName)
              swal({
                title: 'Successfull!!!',
                text: ' Uploaded Successfully',
                icon: 'success',
              });
              setMultipleImgKey([])
              setSelectImage('')
              setUploadLoading(false)
              setUploadDone(true)
            }
          });
        })
      } catch (err) {
        swal({
          title: 'Error!!!',
          text: 'Error please try again',
          icon: 'error',
        });
        console.log(err);
        setUploadLoading(false)
      }
      createRoom({
        variables: {
          "roomInput": {
            "roomName": `${roomN}`,
            "roomNo": `${roomNo}`,
            "roomType": `${roomType}`,
            "roomDiscription": `${roomDes}`,
            "roomImg": imageKey,
            "facility": `${strFaculty}`,
            "feature": `${strService}`,
            "service": `${strFeature}`,
            "price": `${price}`,
          }
        }
      })
      setRoomN('')
      setRoomNo('')
      setRoomDes('')
      setRoomType('')
      setTags([""])
      setServiceTags([""])
      setFeatureTags([""])
      setPrice('')
      setImageBox()
      setSelectImage()
      setMultipleImgKey([])
    }
  };
  console.log("uploadLoading", uploadLoading)
  console.log(tags)
  return (
    <>
      <Container style={{ border: "2px solid #fbc531", marginTop: 10 }}>
        <Row>
          <Col md={12}>
            <Form noValidate validated={validated} onSubmit={handleSubmit} style={{ marginTop: 30 }}>
              <Row className="mb-3">
                <Form.Group as={Col} md="4" controlId="validationCustom01">
                  <Form.Label style={{ fontFamily: "Dm sans", fontSize: 12, fontWeight: 'bold' }}>Room Name</Form.Label>
                  <Form.Control
                    required
                    type="text"
                    placeholder="Room Name"
                    style={{ fontFamily: "Dm sans", fontSize: 12 }}
                    onChange={(e) => setRoomN(e.target.value)}
                    value={roomN}
                  />
                </Form.Group>
                <Form.Group as={Col} md="8" controlId="validationCustom02">
                  <Form.Label style={{ fontFamily: "Dm sans", fontSize: 12, fontWeight: 'bold' }}>Room Description</Form.Label>
                  <Form.Control
                    required
                    as="textarea"
                    rows={1}
                    placeholder="Room Description"
                    style={{ fontFamily: "Dm sans", fontSize: 12 }}
                    onChange={(e) => setRoomDes(e.target.value)}
                    value={roomDes}
                  />
                </Form.Group>
              </Row>

              <Row className="mb-3">
                <Form.Group as={Col} md="4" controlId="validationCustom03">
                  <Form.Label style={{ fontFamily: "Dm sans", fontSize: 12, fontWeight: 'bold' }}>Room Number</Form.Label>
                  <Form.Control type="number" placeholder="Room number" onChange={(e) => setRoomNo(e.target.value)} required style={{ fontFamily: "Dm sans", fontSize: 12 }} value={roomNo} />
                </Form.Group>
                <Form.Group as={Col} md="8" controlId="validationCustom04">
                  <Form.Label style={{ fontFamily: "Dm sans", fontSize: 12, fontWeight: 'bold' }}>Facility</Form.Label>
                  <ReactTagInput tags={tags} onChange={(newTags) => setTags(newTags)} />
                </Form.Group>
              </Row>

              <Row className="mb-3">
                <Form.Group as={Col} md="6" controlId="validationCustom01">
                  <Form.Label style={{ fontFamily: "Dm sans", fontSize: 12, fontWeight: 'bold' }}>Service</Form.Label>
                  <ReactTagInput tags={servicetags} onChange={(Servicetags) => setServiceTags(Servicetags)} />
                </Form.Group>
                <Form.Group as={Col} md="6" controlId="validationCustom05" >
                  <Form.Label style={{ fontFamily: "Dm sans", fontSize: 12, fontWeight: 'bold' }}>Feature</Form.Label>
                  <ReactTagInput tags={featuretags} onChange={(FeatureTags) => setFeatureTags(FeatureTags)} />
                </Form.Group>
              </Row>

              <Row className="mb-3">
                <Form.Group as={Col} md="6" controlId="validationCustom01">
                  <Form.Label style={{ fontFamily: "Dm sans", fontSize: 12, fontWeight: 'bold' }}>Multiple Image</Form.Label>
                  <Form.Control style={{ fontSize: '12px' }} type="file" name="file" onChange={multipleImg} multiple accept="image/*" />
                </Form.Group>
                <Form.Group as={Col} md="6" controlId="validationCustom05" >
                  <Form.Label style={{ fontFamily: "Dm sans", fontSize: 12, fontWeight: 'bold' }}>Room Type</Form.Label>
                  <Form.Control as="select" required className="formInput" onChange={(e) => setRoomType(e.target.value)} value={roomType} style={{ fontFamily: "Dm sans", fontSize: 12 }}>
                    <option value="" selected="selected" disabled="disabled">Choose Room Type</option>
                    <option value="Luxury Suit Room">Luxury Suit Room</option>
                    <option value="Executive Room">Executive Room</option>
                    <option value="Deluxe AC Room">Deluxe AC Room</option>
                    <option value="Suit Room">Suit Room</option>
                  </Form.Control>
                </Form.Group>
              </Row>

              <Row className="mb-3">
                <Form.Group as={Col} md="6" controlId="validationCustom01">
                  <Form.Label style={{ fontFamily: "Dm sans", fontSize: 12, fontWeight: 'bold' }}>Price</Form.Label>
                  <Form.Control
                    required
                    type="number"
                    placeholder="Price"
                    style={{ fontFamily: "Dm sans", fontSize: 12 }}
                    onChange={(e) => setPrice(e.target.value)}
                    value={price}
                  />
                </Form.Group>
              </Row>
              {
                uploadLoading ?
                  <Spinner animation="border" role="status"></Spinner> :
                  <Button type="submit" style={{ marginBottom: 10, background: "#feca57", border: "2px solid #feca57 ", color: "#000", fontFamily: 'DM Sans', fontSize: '12px' }}>Add Room</Button>
              }
            </Form>
          </Col>
        </Row>
      </Container>


    </>
  )
}
