import React from 'react'
import { Container, Row, Col, Image, Button, Card } from 'react-bootstrap'
import e1 from '../Images/DSCN5585.JPG'
import e2 from '../Images/e2.jpg'
import e3 from '../Images/car-racing-video-game-arcade.jpg'
import e4 from '../Images/DSCN5626.JPG'
import e5 from '../Images/AcRoom.jpg'
import '../ComponentsCss/OurServices.css'
export default function OurServices() {
    return (
        <Container fluid >
            <Row>
                <Col md={12} style={{ textAlign: 'center', marginTop: 50 }}>
                    <h2 style={{ color: 'black', fontWeight: 'bold', fontFamily: 'Kaushan Script' }} >
                        Our Top Class Services

                    </h2>
                    <h2 className='mx-auto d-block' style={{ backgroundColor: '#ffb606 ', height: 2, width: '15%', marginTop: 20 }}> </h2>
                    <Container>
                        <p style={{ marginTop: 20, color: ' #666666', fontWeight: 400, fontFamily: 'Poppins' }}>
                            The Hotel offers some of the city’s finest accommodation, dining experiences, and comprehensive conferencing and banqueting facilities. We aim to redefine a new dimension of luxury and relaxation.
                        </p>
                    </Container>
                </Col>
            </Row>


            <Row>
                <Col md={7} style={{ paddingBottom: 25 }}>
                    <Image src={e1} className='img-fluid' style={{ height: 350, width: '100%' }} />
                    <h3 style={{ color: '#002147', fontWeight: 'bold', marginTop: 10, fontFamily: 'Poppins' }}>
                        Restaurant
                    </h3>
                </Col>
                <Col md={5}>
                    <Image src={e3} className='img-fluid' style={{ height: 350, width: '100%' }} />
                    <h3 style={{ color: '#002147', fontWeight: 'bold', marginTop: 10, fontFamily: 'Poppins' }}>
                        Game Zone
                    </h3>
                </Col>
            </Row>
            <Row>
                <Col md={4} style={{ paddingBottom: 25 }}>
                    <Image src={e4} className='img-fluid' style={{ height: 300, width: '100%' }} />
                    <h3 style={{ color: '#002147', fontWeight: 'bold', marginTop: 10, fontFamily: 'Poppins' }}>
                        Banquet Hall
                    </h3>
                </Col>
                <Col md={4}>
                    <Image src={e2} className='img-fluid' style={{ height: 300, width: '100%' }} />
                    <h3 style={{ color: '#002147', fontWeight: 'bold', marginTop: 10, fontFamily: 'Poppins' }}>
                        Wedding Hall
                    </h3>
                </Col>
                <Col md={4}>
                    <Image src={e5} className='img-fluid' style={{ height: 300, width: '100%' }} />
                    <h3 style={{ color: '#002147', fontWeight: 'bold', marginTop: 10, fontFamily: 'Poppins' }}>
                        AC Rooms
                    </h3>
                </Col>
            </Row>



        </Container>
    )
}

