
import React from 'react'
import { Container, Row, Col } from 'react-bootstrap'
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import RoomDetails from './RoomDetails';

export default function RoomDetailsSlick() {
    const settings = {

        className: "center",
        infinite: true,

        slidesToShow: 1,
        swipeToSlide: true,
        autoplay: true,
        autoplaySpeed: 2000,

        afterChange: function (index) {

        },
        responsive: [
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    infinite: true,
                    dots: true
                }
            },
            {
                breakpoint: 600,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    initialSlide: 1
                }
            },
            {
                breakpoint: 480,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1
                }
            }
        ]
    };
    return (
        <div>
            <Container fluid  >
                <Row>
                    <Col md={12}>
                        <Container fluid style={{ marginTop: '30px', marginBottom: 50 }}>
                            <Row style={{ marginTop: '10px', marginLeft: '5px', marginRight: '5px' }}>
                                <Slider {...settings}>
                                    <div>
                                        <RoomDetails />
                                    </div>
                                    <div>
                                        <RoomDetails />
                                    </div>
                                    <div>
                                        <RoomDetails />
                                    </div>
                                    <div>
                                        <RoomDetails />
                                    </div>
                                </Slider>
                            </Row>

                        </Container>
                    </Col>
                </Row>

            </Container>

        </div>
    )
}