import { Card, Button, Container, Col, Row, Carousel } from 'react-bootstrap'
import '../ComponentsCss/Header.css'
import Slide from 'react-reveal/Slide';
export default function Header() {
  return (
    <>
      <Carousel variant="dark">
        <Carousel.Item>
          <header id='header'>
            <div className='intro'>
              <Container className="intro-text">
                <Row>
                  <Col sm={7}>
                    <Slide left>
                      <p style={{
                        color: '#fff', fontSize: '50px',
                        fontWeight: '400', marginTop: '60px', fontSize: 30, textAlign: 'left'
                      }} id="hero_text">
                        We will always be your favorite hotel!
                      </p>
                    </Slide>
                    <Slide Right >
                      <h5 style={{
                        color: '#fff', fontSize: '45px',
                        fontWeight: 'bolder', marginTop: '-30px', textAlign: 'left', lineHeight: 1.2
                      }} >
                        Relish A Comfortable And Convenient Stay With Us.
                      </h5>
                    </Slide>
                  </Col>
                  <Col sm={5}>
                    {/* <Card id="headercard">
                                    <Card.Body>
                                        <Card.Title className="kk">How much do you need</Card.Title>
                                        <span id="jk">Enter your details below and we will get back to you
                                        </span>
                                        <Form style={{marginTop:'30px'}}>
                                            <Row >
                                            <Col ms={6}>
                                            <Form.Group >
                                                    <Form.Label>First Name</Form.Label>
                                                    <Form.Control type="text" placeholder="Enter first name" />
                                                </Form.Group>
                                            </Col>
                                            <Col ms={6}>
                                            <Form.Group >
                                                    <Form.Label >Last Name</Form.Label>
                                                    <Form.Control type="text" placeholder="Enter last name" />
                                                </Form.Group>
                                            </Col>
                                                
                                            </Row>
                                            <Row >
                                            <Col ms={6}>
                                            <Form.Group >
                                                    <Form.Label>Email</Form.Label>
                                                    <Form.Control type="email" placeholder="Enter email" />
                                                </Form.Group>
                                            </Col>
                                            <Col ms={6}>
                                            <Form.Group >
                                                    <Form.Label >Mobile Number</Form.Label>
                                                    <Form.Control type="text" placeholder="Enter mobile number" />
                                                </Form.Group>
                                            </Col>
                                                
                                            </Row>
                                            <Row >
                                            <Col ms={6}>
                                            <Form.Group >
                                                    <Form.Label>City</Form.Label>
                                                    <Form.Control type="text" placeholder="Enter your city " />
                                                </Form.Group>
                                            </Col>
                                            <Col ms={6}>
                                            <Form.Group >
                                                    <Form.Label >Pincode</Form.Label>
                                                    <Form.Control type="text" placeholder="Enter your pincode" />
                                                </Form.Group>
                                            </Col>
                                                
                                            </Row>

                                        </Form>


                                    </Card.Body>
                                    <button type="button" className="btn btn-primary mx-auto d-block" id="button">Contact Us</button>
                                </Card> */}
                  </Col>
                </Row>
              </Container>

            </div>

          </header>
        </Carousel.Item>



      </Carousel>
    </>
  )
}